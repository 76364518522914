import { entityDependencyContent } from '../content/EntityDependencyContent';
import { overviewContent } from '../content/EntityOverviewContent';
import { entityTechDocsContent } from '../content/EntityTechDocsContent';
import { EntityApiDefinitionCard } from '@backstage/plugin-api-docs';
import { EntityLayout } from '@backstage/plugin-catalog';
import { Grid } from '@material-ui/core';
import React from 'react';

export const entityApiPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/dependencies" title="Dependencies">
      {entityDependencyContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {entityTechDocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);
