import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { MyPluginBackendClient } from './api/MyPluginBackendClient';
import { myPluginApiRef } from './api';
import { rootRouteRef } from './routes';

export const pltMonitoringPlugin = createPlugin({
  id: 'my-plugin',
  apis: [
    createApiFactory({
      api: myPluginApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new MyPluginBackendClient({ discoveryApi, fetchApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const PltMonitoringPage = pltMonitoringPlugin.provide(
  createRoutableExtension({
    name: 'EntityMyPluginContent',
    component: () =>
      import('./components/MonitoringComponent').then(
        m => m.MonitoringComponent,
      ),
    mountPoint: rootRouteRef,
  }),
);
