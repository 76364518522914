import { CodeSnippet, InfoCard } from '@backstage/core-components';
import { EntitySwitch } from '@backstage/plugin-catalog';
import { EntitySonarQubeCard } from '@backstage-community/plugin-sonarqube';
import { isSonarQubeAvailable } from '@backstage-community/plugin-sonarqube-react';
import {
  EntityGithubActionsContent,
  isGithubActionsAvailable,
} from '@backstage-community/plugin-github-actions';
import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';

const SonarCubeEmptyState = () => {
  const ENTITY_YAML = `metadata:
  name: example
  annotations:
    - sonarqube.org/project-key: 'my-sonarq-project-key'`;

  return (
    <InfoCard title="Sonarcube not configured">
      <Typography variant="body1">
        Update your sonarcube annotation to your entity YAML as shown in the
        highlighted example below:
      </Typography>
      <CodeSnippet
        text={ENTITY_YAML}
        language="yaml"
        showLineNumbers
        highlightedNumbers={[3, 4, 5, 6]}
        customStyle={{ background: 'inherit', fontSize: '115%' }}
      />
      <Button
        variant="contained"
        color="primary"
        target="_blank"
        href="https://backstage.prod.accelerator.sanofi/docs/default/system/platform-backstage/plugins/sonarqube/#setup"
      >
        Read more
      </Button>
    </InfoCard>
  );
};

const GithubActionEmptyState = () => {
  const ENTITY_YAML = `metadata:
  name: example
  annotations:
    - github.com/project-slug: my-github-project-slug`;

  return (
    <InfoCard title="Configure your github project">
      <Typography variant="body1">
        Add the github annotation to your entity YAML as shown in the
        highlighted example below:
      </Typography>
      <CodeSnippet
        text={ENTITY_YAML}
        language="yaml"
        showLineNumbers
        highlightedNumbers={[3, 4, 5, 6]}
        customStyle={{ background: 'inherit', fontSize: '115%' }}
      />
      <Button
        variant="contained"
        color="primary"
        target="_blank"
        href="https://backstage.prod.accelerator.sanofi/docs/default/system/platform-backstage/plugins/github/#setup"
      >
        Read more
      </Button>
    </InfoCard>
  );
};

export const entityCicdContent = (
  <Grid container alignItems="stretch">
    <EntitySwitch>
      <EntitySwitch.Case if={isSonarQubeAvailable}>
        <Grid item md={2} xs={12}>
          <EntitySonarQubeCard />
        </Grid>
      </EntitySwitch.Case>
      <EntitySwitch.Case>
        <Grid item md={2} xs={12}>
          <SonarCubeEmptyState />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isGithubActionsAvailable}>
        <Grid item md={10} xs={12}>
          <EntityGithubActionsContent />
        </Grid>
      </EntitySwitch.Case>
      <EntitySwitch.Case>
        <Grid item md={10} xs={12}>
          <GithubActionEmptyState />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);
