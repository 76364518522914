import React from 'react';
import { useRouteRef } from '@backstage/core-plugin-api';
import { GetEntitiesResponse } from '@backstage/catalog-client';
import {
  entityRouteRef,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import {
  ANNOTATION_VIEW_URL,
  getCompoundEntityRef,
} from '@backstage/catalog-model';
import {
  InfoCard,
  ItemCardGrid,
  ItemCardHeader,
  Link,
  LinkButton,
} from '@backstage/core-components';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import GitHub from '@material-ui/icons/GitHub';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Alert from '@material-ui/lab/Alert';

export const HomeMyReposCard = ({
  userEntitiesReposProps,
}: {
  userEntitiesReposProps: GetEntitiesResponse | undefined;
}) => {
  const catalogEntityRoute = useRouteRef(entityRouteRef);

  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();

  return (
    <InfoCard
      title="My components"
      deepLink={{
        title: 'View all components',
        link: '/catalog?filters[kind]=component&filters[user]=all',
      }}
    >
      <ItemCardGrid>
        {userEntitiesReposProps?.items.map(userEntity => (
          <Card key={userEntity.metadata.uid}>
            <CardMedia>
              <Link to={catalogEntityRoute(getCompoundEntityRef(userEntity))}>
                <ItemCardHeader
                  title={userEntity.metadata.title}
                  subtitle={userEntity.spec?.type}
                />
              </Link>
            </CardMedia>
            <Divider />
            <CardContent>
              {userEntity.metadata.description &&
                (userEntity.metadata.description.length > 100
                  ? `${userEntity.metadata.description.substring(0, 100)}...`
                  : userEntity.metadata.description)}
            </CardContent>
            <CardActions>
              <Grid container direction="row" justifyContent="space-around">
                <Tooltip title="Starred Entity">
                  <IconButton
                    edge="start"
                    aria-label="unstar"
                    onClick={() => toggleStarredEntity(userEntity)}
                  >
                    {isStarredEntity(userEntity) ? (
                      <StarIcon style={{ color: '#f3ba37' }} />
                    ) : (
                      <StarIcon />
                    )}
                  </IconButton>
                </Tooltip>
                <LinkButton
                  color="primary"
                  to={`${userEntity.metadata.annotations?.[
                    ANNOTATION_VIEW_URL
                  ].toString()}`}
                >
                  <GitHub />
                </LinkButton>
                <LinkButton
                  color="primary"
                  to={catalogEntityRoute(getCompoundEntityRef(userEntity))}
                >
                  <ArrowForward />
                </LinkButton>
              </Grid>
            </CardActions>
          </Card>
        ))}
      </ItemCardGrid>
      {userEntitiesReposProps?.items.length === 0 && (
        <Alert severity="info">
          <Typography variant="body1">
            <b>No components found!</b> But no worry you are on a good track!{' '}
            <br /> You can start onboarding your components directly from
            backstage using the{' '}
            <Link to="catalog-import" target="_blank">
              <b>workflow here</b>
            </Link>
            . <br /> Or follow the documentation related to{' '}
            <Link
              to="docs/default/system/platform-backstage/cataloging/catalog-info"
              target="_blank"
            >
              <b>cataloging components</b>
            </Link>{' '}
            if you need more insights.
          </Typography>
        </Alert>
      )}
    </InfoCard>
  );
};
