import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import {
  UserSettingsProfileCard,
  UserSettingsAppearanceCard,
  UserSettingsIdentityCard,
} from '@backstage/plugin-user-settings';
import {
  BackstageUserIdentity,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { SettingsPreferencesCard } from './SettingsPreferencesCard';

export const SettingsGeneral = () => {
  const [profileIdentity, setProfileIdentity] = useState<
    BackstageUserIdentity | undefined
  >();
  const [preferredTeam, setPreferredTeam] = useState<string | null>(null);
  const identityApi = useApi(identityApiRef);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!profileIdentity) {
          const [profileId] = await Promise.all([
            identityApi.getBackstageIdentity(),
          ]);
          setProfileIdentity(profileId);
          const storedPreferredTeam = localStorage.getItem('preferredTeam');
          setPreferredTeam(storedPreferredTeam);
        }
      } catch (error) {
        throw new Error('Error getting entities by user');
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileIdentity]);

  const handleTeamChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedTeam = event.target.value as string;
    setPreferredTeam(selectedTeam);
    localStorage.setItem('preferredTeam', selectedTeam);
    window.location.reload();
  };

  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={12} md={6}>
        <UserSettingsProfileCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <UserSettingsAppearanceCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <UserSettingsIdentityCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <SettingsPreferencesCard
          profileIdentity={profileIdentity}
          onChange={handleTeamChange}
          preferredTeam={preferredTeam}
        />
      </Grid>
    </Grid>
  );
};
