import {
  EntityConsumedApisCard,
  EntityProvidedApisCard,
} from '@backstage/plugin-api-docs';
import { Grid } from '@material-ui/core';
import React from 'react';

export const entityApiContent = (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item md={6}>
      <EntityProvidedApisCard />
    </Grid>
    <Grid item md={6}>
      <EntityConsumedApisCard />
    </Grid>
  </Grid>
);
