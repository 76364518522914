import { EntityAboutCard, EntityLayout, EntityLinksCard } from '@backstage/plugin-catalog';
import React from 'react';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { entityDependencyContent } from '../content/EntityDependencyContent';
import { entityTechDocsContent } from '../content/EntityTechDocsContent';
import { Grid } from '@material-ui/core';

export const entitySaasPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} >
        <Grid item md={3} xs={12}>
          <EntityAboutCard variant="gridItem" />
          <EntityLinksCard />
        </Grid>
        <Grid item xs={9}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/dependencies" title="Dependencies">
      {entityDependencyContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {entityTechDocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

