import { entityWarningContent } from './EntityWarningContent';
import {
  EntityConsumingComponentsCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityHasComponentsCard,
  EntityLinksCard,
  EntitySwitch,
  isKind,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import {
  EntityGithubInsightsLanguagesCard,
  EntityGithubInsightsReadmeCard,
  EntityGithubInsightsReleasesCard,
  isGithubInsightsAvailable,
} from '@roadiehq/backstage-plugin-github-insights';
import { Grid } from '@material-ui/core';
import React from 'react';

const overviewDefault = (
  <Grid container spacing={3} alignItems="baseline">
    {entityWarningContent}
    <Grid item md={3} xs={12}>
      <EntityAboutCard variant="gridItem" />
      <EntityLinksCard variant="gridItem" />
      <EntitySwitch>
        <EntitySwitch.Case if={isGithubInsightsAvailable}>
          <EntityGithubInsightsLanguagesCard />
          <EntityGithubInsightsReleasesCard />
        </EntitySwitch.Case>
      </EntitySwitch>
    </Grid>
    <EntitySwitch>
      <EntitySwitch.Case if={isGithubInsightsAvailable}>
        <Grid item md={9} xs={12}>
          <EntityGithubInsightsReadmeCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);

const overviewSystem = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6} xs={12}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityLinksCard />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityHasComponentsCard />
    </Grid>
  </Grid>
);

const overviewResource = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6} xs={12}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>
    <Grid item xs={12}>
      <EntityLinksCard />
    </Grid>
  </Grid>
);

const overviewContentApi = (
  <Grid container spacing={3} alignItems="baseline">
    {entityWarningContent}
    <Grid container item md={12}>
      <Grid item md={6} xs={12}>
        <EntityAboutCard variant="gridItem" />
      </Grid>
      <Grid item md={6}>
        <EntityProvidingComponentsCard />
        &nbsp;
        <EntityConsumingComponentsCard />
      </Grid>
      <Grid item xs={12}>
        <EntityLinksCard />
      </Grid>
    </Grid>
  </Grid>
);

const OVERVIEW_PAGES = [
  {
    kind: 'resource',
    pageComponent: overviewResource,
  },
  {
    kind: 'api',
    pageComponent: overviewContentApi,
  },
  {
    kind: 'system',
    pageComponent: overviewSystem,
  },
];

export const overviewContent = (
  <EntitySwitch>
    {OVERVIEW_PAGES.map((page): React.JSX.Element => {
      return (
        <EntitySwitch.Case
          key={`${page.kind}_overview`}
          if={isKind(`${page.kind}`)}
          children={page.pageComponent}
        />
      );
    })}
    )<EntitySwitch.Case>{overviewDefault}</EntitySwitch.Case>
  </EntitySwitch>
);
