import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#7a00e6',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 220.83 219.24"
    >
      <path
        className={classes.path}
        d="m147.33,144.27l-85.46,49.34c-4.78,2.76-10.13-2.59-7.37-7.37L147.33,25.47l68.99,119.49c2.96-10.83,4.52-22.65,4.52-35.33C220.83,41.31,177.94,0,108.03,0,42.9,0,0,41.31,0,109.62s42.9,109.62,112.8,109.62c39.58,0,70.93-15.86,89.45-43.27l-54.92-31.71Z"
      />
      <path 
        className={classes.path}
        d="m74.09,174.02l73.23-42.28,60.49,34.92c1.38-2.62,2.65-5.32,3.82-8.11l-64.31-111.38-73.23,126.85h0Z"/>
    </svg>
  );
};

export default LogoIcon;
