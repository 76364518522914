import React from 'react';
import { Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { BackstageUserIdentity } from '@backstage/core-plugin-api';
import { InfoCard } from '@backstage/core-components';

export const SettingsPreferencesCard = (props: {
  profileIdentity: BackstageUserIdentity | undefined;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  preferredTeam: string | null;
}) => {
  return (
    <InfoCard title="Preferences">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        style={{ padding: 10 }}
      >
        <Grid item>
          <Typography variant="subtitle1">Preferred team:</Typography>
          <Typography variant="body2" style={{ color: 'gray' }}>
            Choosing a preferred team will redirect your homepage to your team
            page
          </Typography>
        </Grid>
        <Grid item>
          {props.profileIdentity && (
            <Select
              id={`${props.profileIdentity?.userEntityRef}-dropDown`}
              onChange={props.onChange}
              value={
                props.preferredTeam ||
                props.profileIdentity?.userEntityRef ||
                ''
              }
            >
              {props.profileIdentity?.ownershipEntityRefs.map(item => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        </Grid>
      </Grid>
    </InfoCard>
  );
};
