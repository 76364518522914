import React from 'react';

import { ScaffolderPage as ScaffolderPageDefault } from '@backstage/plugin-scaffolder';

const headerOptions = {
  pageTitleOverride: 'Scaffolder',
  title: 'Deploy a new project',
  subtitle:
    'Kickstart your new project with the templates we have built in house',
};

export const ScaffolderPage = (
  <ScaffolderPageDefault
    headerOptions={headerOptions}
    groups={[
      {
        title: 'Recommended',
        filter: entity =>
          entity?.metadata?.tags?.includes('recommended') ?? false,
      },
      {
        title: 'Onboarding',
        filter: entity =>
          entity?.metadata?.tags?.includes('onboarding') ?? false,
      },
      {
        title: 'Kickstart new projects',
        filter: entity =>
          entity?.metadata?.tags?.includes('prebuilt-deployments') ?? true,
      },
      {
        title: 'Available 3rd party platforms',
        filter: entity =>
          entity?.metadata?.tags?.includes('tools-configuration') ?? true,
      },
    ]}
  />
);
