import React from 'react';
import { ProfileInfo } from '@backstage/core-plugin-api';
import { Header, HeaderLabel } from '@backstage/core-components';
import { Joke } from './HomePage';
import { SearchContextProvider } from '@backstage/plugin-search-react';

export const HomeNavBarHeader = ({
  jokeProps,
  profileProps,
}: {
  jokeProps: Joke | undefined;
  profileProps: ProfileInfo | undefined;
}) => {
  return (
    <SearchContextProvider>
      <Header
        title="Accelerator Backstage Home"
        subtitle={`Greetings, ${profileProps?.displayName} !`}
      >
        {jokeProps && (
          <HeaderLabel
            label={`${jokeProps?.setup}`}
            value={`${jokeProps?.punchline}`}
          />
        )}
      </Header>
    </SearchContextProvider>
  );
};
