import React from 'react';
import {
  AwsCostOverviewCard,
  SecurityGroupCard,
} from '@internal/backstage-plugin-overview';
import { useEntity } from '@backstage/plugin-catalog-react';
import { githubAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { GroupEntity } from '@backstage/catalog-model';
import {
  getOrgTeamName,
  getRepoListForTeam,
  queryForDependabot,
} from '@internal/plugin-sanofi-dependabot-monitoring';
import { Grid } from '@material-ui/core';
import { SummaryResourceForTeam } from '@internal/plugin-plt-monitoring';

const OverViewGroupSummaryBlock = () => {
  const { entity } = useEntity();
  const auth = useApi(githubAuthApiRef);
  const { org, teamName } = getOrgTeamName(entity as GroupEntity);
  const securityResult = useAsync(async (): Promise<any> => {
    const token = await auth.getAccessToken(['repo', 'security_events']);
    const repoList = await getRepoListForTeam(org, teamName, token);
    return await queryForDependabot({ token, repoList, org, widgetView: true });
  }, [teamName]);

  return (
    <div
      style={{ padding: '8px' }}
      key={getOrgTeamName(entity as GroupEntity).teamName}
    >
      <Grid container direction="row" spacing={2}>
        <Grid container>
          <Grid item md={12} xs={12}>
            <SecurityGroupCard
              result={securityResult}
              teamName={getOrgTeamName(entity as GroupEntity).teamName}
              key={teamName}
            />
          </Grid>
          <Grid item lg={6} md={12} xs={12}>
            <AwsCostOverviewCard />
          </Grid>
          <Grid item lg={6} md={12} xs={12}>
            <SummaryResourceForTeam />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default OverViewGroupSummaryBlock;
