import React from 'react';
import { Config } from '@backstage/config';
import { InfoCard } from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import Box from '@material-ui/core/Box';
import { useAsync } from 'react-use';
import { Grid, Typography } from '@material-ui/core';
import { costApiRef } from '@internal/plugin-aws-cost';

const DAY_IN_MS = 24 * 60 * 60 * 1000;
const NUMBER_OF_MAX_DISPLAYED_ELEMENTS = 7;

const getIcon = (name: string, config: Config) => {
  return (
    <img
      src={`${config.getString('app.baseUrl')}/awsIcons/${
        name === 's3' ? 's3bucket' : name
      }.svg`}
      alt={name}
    />
  );
};

const CostData = ({
  data: { latestData },
  config,
}: {
  data: {
    latestData: AwsCostDataResults;
  };
  config: Config;
}) => {
  const structDataSeries = (
    dataSeries: AwsCostDataSeries[],
  ): CostDataInfo[] => {
    return dataSeries.map((serie: AwsCostDataSeries, index: number) => {
      const [line] = serie.group_tags;
      const accumulateCostForLine = latestData.data.attributes.values[
        index
      ].reduce((acc: number, val: number) => acc + val, 0);
      const resourceName = line.substring('aws_product:'.length);
      const displayName =
        resourceName === 'secretsmanager' ? 'Sec. Manag' : resourceName;

      return {
        key: line,
        resourceName,
        displayName,
        amount: +accumulateCostForLine.toFixed(2),
      };
    });
  };

  const orderedSeries = (costDataSeries: CostDataInfo[]) => {
    return costDataSeries.sort((a, b) => {
      if (a.amount < b.amount) {
        return 1;
      } else if (a.amount > b.amount) {
        return -1;
      }
      return 0;
    });
  };

  const structuredData = structDataSeries(latestData.data.attributes.series);
  const orderedData = orderedSeries(structuredData);
  const getElementsFilteredList = () => {
    return orderedData
      .map((data: CostDataInfo) => (
        <Grid item xs={12} key={data.key}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginX: '5px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginX: '5px',
              }}
            >
              {getIcon(data.resourceName, config)}
              &nbsp;
              <Typography variant="caption">{data.displayName} </Typography>
            </Box>
            <Typography variant="caption">{`$${data.amount}`}</Typography>
          </Box>
        </Grid>
      ))
      .slice(0, NUMBER_OF_MAX_DISPLAYED_ELEMENTS);
  };

  return (
    <>
      {getElementsFilteredList()}
      {orderedData.length > NUMBER_OF_MAX_DISPLAYED_ELEMENTS && (
        <Grid item xs={12} key="h3">
          <Box>
            <Typography variant="subtitle2" align="left">
              + {orderedData.length - NUMBER_OF_MAX_DISPLAYED_ELEMENTS} more
            </Typography>
          </Box>
        </Grid>
      )}
    </>
  );
};

export const AwsCostOverviewCard = () => {
  const config = useApi(configApiRef);
  const { entity } = useEntity();
  const costApi = useApi(costApiRef);
  const periodInDays = 7;
  const minusOneSettings = {
    fromTimestamp: new Date().getTime() - periodInDays * DAY_IN_MS,
    nowTimestamp: new Date().getTime(),
    filters: [
      { key: 'env', value: '*' },
      { key: 'team', value: entity.metadata.name },
      { key: 'service', value: '*' },
    ],
  };
  const latestSettings = {
    fromTimestamp: new Date().getTime() - periodInDays * DAY_IN_MS,
    nowTimestamp: new Date().getTime(),
    filters: [
      { key: 'env', value: '*' },
      { key: 'team', value: entity.metadata.name },
      { key: 'service', value: '*' },
    ],
  };

  const { loading: loadPreviousMonth, error: previousWeekError } = useAsync(
    () => costApi.getCostMgmtMetrics(minusOneSettings),
  );
  const {
    loading: loadingLatest,
    value: latestData,
    error: latestDataError,
  } = useAsync(() => costApi.getCostMgmtMetrics(latestSettings));

  const error = previousWeekError || latestDataError;

  if (loadingLatest || loadPreviousMonth)
    return <InfoCard title="AWS Cost" subheader="Loading" />;

  if (error || !latestData?.data?.attributes?.values) {
    return (
      <InfoCard
        title="AWS Cost"
        subheader="Unable to fetch data from the API. Please check the configuration."
      />
    );
  }

  // Calculate the total amount for each resource the timeseries data structure
  const globalTotal = latestData.data.attributes.values.reduce(
    (globalAccumulator: number, product: any) => {
      return (
        globalAccumulator +
        product.reduce(
          (innerAcc: number, innerVal: number) =>
            innerVal ? innerAcc + innerVal : innerAcc,
          0,
        )
      );
    },
    0,
  );

  return (
    <InfoCard
      title="AWS Cost"
      subheader={`Total : $${globalTotal.toFixed(2)}`}
      deepLink={{
        title: 'View full cost data',
        link: `/catalog/default/Group/${entity.metadata.name}/aws-cost`,
      }}
    >
      <Grid
        container
        style={{
          minHeight: 280,
        }}
        // handles whether to display the results for the summary
      >
        <CostData
          config={config}
          data={{
            latestData,
          }}
        />
      </Grid>
    </InfoCard>
  );
};

type AwsCostDataResults = {
  data: {
    attributes: {
      series: AwsCostDataSeries[];
      values: number[][];
    };
  };
};

type AwsCostDataSeries = {
  group_tags: string[];
  query_index: number;
  unit: [
    {
      family: string;
      name: string;
      plural: string;
    },
  ];
};

type CostDataInfo = {
  key: string;
  resourceName: string;
  displayName: string;
  amount: number;
};
