import { entityDependencyContent } from '../content/EntityDependencyContent';
import { diagramResourceSystem } from '../content/EntityDiagramContent';
import { overviewContent } from '../content/EntityOverviewContent';
import { EntityLayout } from '@backstage/plugin-catalog';
import React from 'react';

export const entityResourcePage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/dependencies" title="Dependencies">
      {entityDependencyContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      {diagramResourceSystem}
    </EntityLayout.Route>
  </EntityLayout>
);
