import React from 'react';
import {
  SettingsLayout,
  UserSettingsFeatureFlags,
  UserSettingsAuthProviders,
} from '@backstage/plugin-user-settings';
import { SettingsGeneral } from './SettingsGeneral';

export const settingsPage = (
  <SettingsLayout>
    <SettingsLayout.Route path="general" title="General">
      <SettingsGeneral />
    </SettingsLayout.Route>
    <SettingsLayout.Route
      path="auth-providers"
      title="Authentication providers"
    >
      <UserSettingsAuthProviders />
    </SettingsLayout.Route>
    <SettingsLayout.Route path="feature-flags" title="Features flags">
      <UserSettingsFeatureFlags />
    </SettingsLayout.Route>
  </SettingsLayout>
);
