import { GroupEntity } from '@backstage/catalog-model';
import { Repository } from '../../types';

export const capitalize = (s: string) => {
  return s.charAt(0).toLocaleUpperCase() + s.slice(1);
};

export const getOrgTeamName = (entity: GroupEntity) => {
  const teamSlug = entity.metadata?.annotations?.['github.com/team-slug'] || '';
  const [org, teamName] = teamSlug.split('/');
  return { org, teamName };
};

export const addRepoNameInEachIssue = (
  rawRepository: Repository,
  name: string,
  org: string,
  hostname: string,
): Repository => {
  return {
    vulnerabilityAlerts: {
      totalCount: rawRepository.vulnerabilityAlerts.totalCount,
      nodes: rawRepository.vulnerabilityAlerts.nodes.map(node => ({
        ...node,
        repoName: name,
        linkGH: `https://github.com/${org}/${name}/security/dependabot`,
        detailsUrl: { hostname, owner: org, repo: name },
      })),
    },
  };
};
