import { EntityHasApisCard } from '@backstage/plugin-api-docs';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import {
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
} from '@backstage/plugin-catalog';
import { Grid } from '@material-ui/core';
import React from 'react';

export const entityDependencyContent = (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item md={7} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={500} />
    </Grid>
    <Grid item md={5} xs={12}>
      <EntityDependsOnComponentsCard variant="gridItem" />
      &nbsp;
      <EntityDependsOnResourcesCard variant="gridItem" />
      &nbsp;
      <EntityHasApisCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityHasComponentsCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityHasResourcesCard variant="gridItem" />
    </Grid>
  </Grid>
);
