import { createApiFactory, createPlugin, createRoutableExtension, discoveryApiRef, fetchApiRef, identityApiRef } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { LaunchDarklyApi, launchDarklyApiRef } from './api';

export const ldFlagMonitorPlugin = createPlugin({
  id: 'ld-flag-monitor',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: launchDarklyApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
        fetchApi: fetchApiRef
      },
      factory: ({ discoveryApi, identityApi, fetchApi }) => {
        return new LaunchDarklyApi({
          discoveryApi,
          identityApi,
          fetchApi,
        });
      }
    })
  ]
});

export const LdTeamFlagMonitorPage = ldFlagMonitorPlugin.provide(
  createRoutableExtension({
    name: 'LdTeamFlagMonitorPage',
    component: () =>
      import('./components/TeamFlagsPage').then(m => m.LaunchDarklyGroupFlags),
    mountPoint: rootRouteRef,
  }),
);
