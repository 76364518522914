import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import {
  JiraSearchPayload,
  MAX_RESULTS,
  PaginatedJiraIssues,
  Project,
} from '.';
import { Options } from '../SanofiDoraMetrics';

const DEFAULT_PROXY_PATH = '/jira/api';
const DEFAULT_REST_API_VERSION = 'latest';

const JIRA_FIELDS = [
  'resolutiondate',
  'created',
  'priority',
  'severity',
  'issuetype',
  'project',
  'component',
  'environment',
];
export class JiraClient {
  private readonly discoveryApi: DiscoveryApi;
  private readonly proxyPath: string;
  private readonly apiVersion: string;
  private readonly fetchApi: FetchApi;
  constructor({ discoveryApi, configApi, fetchApi }: Options) {
    this.discoveryApi = discoveryApi;
    this.fetchApi = fetchApi;
    this.proxyPath =
      configApi.getOptionalString('jira.proxyPath') ?? DEFAULT_PROXY_PATH;
    const apiVersion = configApi.getOptionalNumber('jira.apiVersion');
    this.apiVersion = apiVersion
      ? apiVersion.toString()
      : DEFAULT_REST_API_VERSION;
  }

  async getProjectDetails(projectKey: string): Promise<Project> {
    const { apiUrl } = await this.getUrls();
    const request = await this.fetchApi.fetch(
      `${apiUrl}/project/${projectKey}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!request.ok) {
      throw new Error(
        `Unable to fetch ${projectKey} project details, check if the project key provided is correct}`,
      );
    }

    return (await request.json()) as Project;
  }

  async handlePaginatedSearch(
    body: JiraSearchPayload,
    metadata: {
      projectKey: string;
    } = {
      projectKey: '',
    },
  ): Promise<PaginatedJiraIssues> {
    const initialBody = JSON.stringify(body);
    const { apiUrl } = await this.getUrls();
    const request = await this.fetchApi.fetch(`${apiUrl}/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: initialBody,
    });

    if (!request.ok) {
      const message = JSON.parse(await request.text());

      const errorMessage = message?.errorMessages?.length
        ? message?.errorMessages.join(', \n')
        : `Unable to fetch jira deployment metrics for project ${metadata.projectKey}`;
      throw new Error(errorMessage);
    }

    const requestText = await request.text();
    const response = JSON.parse(requestText) as PaginatedJiraIssues;
    const { total, maxResults } = response;

    // Set startAt to the maximum results and test if the total is greater than the max results
    let startAt = maxResults;
    const issues = [...response.issues];
    while (startAt < total) {
      // Create new body with the updated startAt
      let nextBody = null;
      const rawBody = body;
      nextBody = JSON.stringify({
        ...rawBody,
        startAt,
      });

      let nextRequest = null;
      nextRequest = await this.fetchApi.fetch(`${apiUrl}/search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: nextBody,
      });

      if (!nextRequest.ok) {
        throw new Error(
          `failed to fetch data, status ${nextRequest.status}: ${nextRequest.statusText} `,
        );
      }

      const nextResponse = (await nextRequest.json()) as PaginatedJiraIssues;
      issues.push(...nextResponse.issues);

      // Change startAt to the next paginated set of results
      startAt = nextResponse.startAt + MAX_RESULTS;
    }
    return { ...response, issues };
  }
  public generateJiraQuery(jql: string) {
    return {
      maxResults: MAX_RESULTS,
      startAt: 0,
      jql,
      fields: JIRA_FIELDS,
      expand: ['changelog'],
    };
  }

  public async getUrls() {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    if (!proxyUrl) {
      throw new Error('Unable to get proxy url');
    }

    return {
      apiUrl: `${proxyUrl}${this.proxyPath}/rest/api/${this.apiVersion}`,
    };
  }
}
