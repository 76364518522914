import { entityApiContent } from '../content/EntityApiContent';
import { entityCicdContent } from '../content/EntityCicdContent';
import { entityDependencyContent } from '../content/EntityDependencyContent';
import { overviewContent } from '../content/EntityOverviewContent';
import { entityTechDocsContent } from '../content/EntityTechDocsContent';
import { entityGithubDependabotContent } from '../content/EntityDependabotContent';
import {
  EntityLayout,
  EntitySwitch,
  isComponentType,
} from '@backstage/plugin-catalog';
import React from 'react';
import { entitySaasPage } from './EntitySaasPage';

const BASE_COMPONENT_SKELETON = [
  {
    path: '/',
    title: 'Overview',
    children: overviewContent,
  },
  {
    path: '/ci-cd',
    title: 'CI/CD',
    children: entityCicdContent,
  },
  {
    path: '/dependabot-alerts',
    title: 'Dependabot',
    children: entityGithubDependabotContent,
  },
  {
    path: '/dependencies',
    title: 'Dependencies',
    children: entityDependencyContent,
  },
  {
    path: '/docs',
    title: 'Docs',
    children: entityTechDocsContent,
  },
];

const entityBaseComponent = BASE_COMPONENT_SKELETON.map(
  (page): React.JSX.Element => {
    return (
      <EntityLayout.Route
        key={`${page.path}_component`}
        path={page.path}
        title={page.title}
        children={page.children}
      />
    );
  },
);

const entityWebsiteLibPage = <EntityLayout>{entityBaseComponent}</EntityLayout>;

const entityServicePage = (
  <EntityLayout>
    {entityBaseComponent}
    <EntityLayout.Route path="/api" title="API">
      {entityApiContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const COMPONENT_PAGES = [
  {
    componentType: 'service',
    pageComponent: entityServicePage,
  },
  {
    componentType: 'website',
    pageComponent: entityWebsiteLibPage,
  },
  {
    componentType: 'library',
    pageComponent: entityWebsiteLibPage,
  },
  {
    componentType: 'saas',
    pageComponent: entitySaasPage,
  },
  {
    componentType: 'plugin',
    pageComponent: entityServicePage,
  },
  {
    componentType: 'documentation',
    pageComponent: entityWebsiteLibPage,
  },
];

export const entityComponentPage = (
  <EntitySwitch>
    {COMPONENT_PAGES.map((page): React.JSX.Element => {
      return (
        <EntitySwitch.Case
          key={`${page.componentType}_component`}
          if={isComponentType(`${page.componentType}`)}
          children={page.pageComponent}
        />
      );
    })}
    )<EntitySwitch.Case>{entityServicePage}</EntitySwitch.Case>
  </EntitySwitch>
);
