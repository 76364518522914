import { EntityLayout } from '@backstage/plugin-catalog';
import { doraMetricsContent } from '../content/EntityDoraMetricsContent';
import { entityWarningContent } from '../content/EntityWarningContent';
import { EntityTeamPullRequestsContent } from '@backstage-community/plugin-github-pull-requests-board';
import {
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { Grid } from '@material-ui/core';
import React from 'react';
import { entityMonitoringContent } from '../content/EntityMonitoringContent';
import { entityCostAnalysisContent } from '../content/EntityCostAnalysisContent';
import { launchDarklyContent } from '../content/EntityLaunchDarklyContent';
import { entitySecContent } from '../content/EntitySecContent';
import { EntityGroupSummaryCard } from '../components/cards/EntityGroupSummaryCard';
import OverviewGroupSummaryBlock from './components/OverviewGroupSummaryBlock';

export const entityGroupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityGroupSummaryCard variant="fullHeight" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="fullHeight" />
        </Grid>
        <Grid item xs={6} md={6}>
          <OverviewGroupSummaryBlock />
        </Grid>
        <Grid item xs={6} md={6}>
          <EntityMembersListCard memberDisplayTitle="Team members" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      <EntityTeamPullRequestsContent pullRequestLimit={50} />
    </EntityLayout.Route>
    <EntityLayout.Route path="/security" title="Security">
      {entitySecContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/launch-darkly" title="Launch Darkly">
      {launchDarklyContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/aws-inventory" title="AWS Inventory">
      {entityMonitoringContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/aws-cost" title="AWS Cost">
      {entityCostAnalysisContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/dora-metrics" title="Dora metrics">
      {doraMetricsContent}
    </EntityLayout.Route>
  </EntityLayout>
);
