import React from 'react';
import { Grid } from '@material-ui/core';
import { DependabotMonitoringPage } from '@internal/plugin-sanofi-dependabot-monitoring';
import { SecOpsComplianceWidget } from '@internal/plugin-secops-compliance';

export const entitySecContent = (
  <Grid container>
    <Grid item xs={12} md={4}>
      <SecOpsComplianceWidget />
    </Grid>
    <Grid item xs={12} md={8}>
      <DependabotMonitoringPage />
    </Grid>
  </Grid>
);
