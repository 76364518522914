import {
  ScmAuth,
  ScmIntegrationsApi,
  scmAuthApiRef,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  ApiRef,
  BackstageIdentityApi,
  ConfigApi,
  DiscoveryApi,
  OAuthApi,
  OAuthRequestApi,
  ProfileInfoApi,
  SessionApi,
  configApiRef,
  createApiFactory,
  createApiRef,
  discoveryApiRef,
  identityApiRef,
  oauthRequestApiRef,
  storageApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { VisitsStorageApi, visitsApiRef } from '@backstage/plugin-home';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import { TechRadarApiClient } from './components/techradar/TechRadarClientInterface';
import { GithubAuth } from '@backstage/core-app-api';
import { ragAiApiRef, RoadieRagAiClient } from '@internal/plugin-rag-ai';

export const customGithubApiRef: ApiRef<
  OAuthApi & ProfileInfoApi & BackstageIdentityApi & SessionApi
> = createApiRef({
  id: 'internal.auth.github',
});

const defaultGithubAuthInstance = ({
  discoveryApi,
  oauthRequestApi,
  configApi,
}: {
  discoveryApi: DiscoveryApi;
  oauthRequestApi: OAuthRequestApi;
  configApi: ConfigApi;
}) =>
  GithubAuth.create({
    discoveryApi,
    oauthRequestApi,
    configApi,
    defaultScopes: [
      'read:user',
      'read:repo',
      'repo',
      'security_events',
      'read:org',
    ],
    environment: configApi.getOptionalString('auth.environment'),
  });

export const apis: AnyApiFactory[] = [
  // Register the scmIntegrations API
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),

  // Register our custom GitHub API for authentication
  createApiFactory({
    api: customGithubApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      defaultGithubAuthInstance({ discoveryApi, oauthRequestApi, configApi }),
  }),
  // Register the scmAuth API with our custom GitHub API as a dependency
  createApiFactory({
    api: scmAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      ScmAuth.merge(
        ScmAuth.forGithub(
          defaultGithubAuthInstance({
            discoveryApi,
            oauthRequestApi,
            configApi,
          }),
        ),
      ),
  }),
  createApiFactory(techRadarApiRef, new TechRadarApiClient()),
  createApiFactory({
    api: visitsApiRef,
    deps: {
      storageApi: storageApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ storageApi, identityApi }) =>
      VisitsStorageApi.create({ storageApi, identityApi }),
  }),
  createApiFactory({
    api: ragAiApiRef,
    deps: {
      configApi: configApiRef,
      discoveryApi: discoveryApiRef,
      fetchApi: fetchApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, fetchApi, configApi, identityApi }) => {
      return new RoadieRagAiClient({
        discoveryApi,
        fetchApi,
        configApi,
        identityApi,
      });
    },
  }),
];
