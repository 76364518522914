import { EntitySwitch, isKind } from '@backstage/plugin-catalog';
import { entityApiPage } from './page/EntityApiPage';
import { entityComponentPage } from './page/EntityComponentPage';
import { entityDefaultPage } from './page/EntityDefaultPage';
import { entityGroupPage } from './page/EntityGroupPage';
import { entityResourcePage } from './page/EntityResourcePage';
import { entitySystemPage } from './page/EntitySystemPage';
import { entityUserPage } from './page/EntityUserPage';
import React from 'react';

const BASE_PAGES = [
  {
    kind: 'component',
    page: entityComponentPage,
  },
  {
    kind: 'api',
    page: entityApiPage,
  },
  {
    kind: 'group',
    page: entityGroupPage,
  },
  {
    kind: 'user',
    page: entityUserPage,
  },
  {
    kind: 'resource',
    page: entityResourcePage,
  },
  {
    kind: 'system',
    page: entitySystemPage,
  },
];

export const entityPage = (
  <EntitySwitch>
    {BASE_PAGES.map((page): React.JSX.Element => {
      return (
        <EntitySwitch.Case
          key={`${page.kind}_page`}
          if={isKind(`${page.kind}`)}
          children={page.page}
        />
      );
    })}
    )<EntitySwitch.Case>{entityDefaultPage}</EntitySwitch.Case>
  </EntitySwitch>
);
