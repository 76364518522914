import { costApiRef, CostMetrics } from './api';
import {
  fetchApiRef,
  configApiRef,
  createPlugin,
  identityApiRef,
  discoveryApiRef,
  createApiFactory,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const costAnalysisPlugin = createPlugin({
  id: 'aws-cost',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: costApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        configApi: configApiRef,
        identityApi: identityApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, configApi, identityApi, fetchApi }) => {
        return new CostMetrics({
          discoveryApi,
          configApi,
          identityApi,
          fetchApi,
        });
      },
    }),
  ],
});

export const CostAnalysisPage = costAnalysisPlugin.provide(
  createRoutableExtension({
    name: 'CostAnalysisPage',
    component: () =>
      import('./components/CostAnalysisComponent').then(
        costAnalysisModule => costAnalysisModule.CostAnalysisComponent,
      ),
    mountPoint: rootRouteRef,
  }),
);
