import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { Options } from '..';
import { cleanQueryString } from '../../utils';
import { CostMetricQuery } from '../CostAnalysisMetrics';

const DEFAULT_PROXY_PATH = '/datadog/api';
const DEFAULT_REST_API_VERSION = 'v2';
const MAX_LIMIT_RESULT = 100;

export const datadogCostApiRef = createApiRef<DatadogCostApi>({
  id: 'plugin.aws-cost-metrics.datadog.service',
});

export class DatadogCostApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor({ discoveryApi, fetchApi }: Options) {
    this.discoveryApi = discoveryApi;
    this.fetchApi = fetchApi;
  }

  createSearchCostQuery = ({
    fromTimestamp,
    nowTimestamp,
    filters,
    groupBy = ['aws_product'],
    rollUpTimeInSeconds,
  }: CostMetricQuery): DatadogSearchBody => {
    const optionalFilters = filters
      .map(
        (filter: { key: any; value: any }) => `${filter.key}:${filter.value}`,
      )
      .join(',');

    return {
      data: {
        type: 'timeseries_request',
        attributes: {
          from: fromTimestamp,
          to: nowTimestamp,
          queries: [
            {
              data_source: 'cloud_cost',
              query: `(sum:aws.cost.ondemand{${cleanQueryString(
                optionalFilters,
              )}} by {${groupBy.join(
                ', ',
              )}}.rollup(sum, ${rollUpTimeInSeconds}))`,
              name: 'cost_query',
            },
          ],
          formulas: [
            {
              formula: 'cost_query',
              // THIS ONLY WORKS ON SCALAR METRICS
              limit: {
                count: MAX_LIMIT_RESULT,
                order: 'desc',
              },
            },
          ],
        },
      },
    };
  };

  async getUrls() {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    return {
      apiUrl: `${proxyUrl}${DEFAULT_PROXY_PATH}/api/${DEFAULT_REST_API_VERSION}`,
      datadogUrl: `https://api.datadoghq.eu/api/${DEFAULT_REST_API_VERSION}`,
    };
  }

  async getCostMetrics(payload: CostMetricQuery): Promise<any> {
    const { apiUrl } = await this.getUrls();
    const body = this.createSearchCostQuery(payload);
    const request = await this.fetchApi.fetch(`${apiUrl}/query/timeseries`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!request.ok) {
      throw new Error(
        `failed to fetch data from datadog, status ${request.status}: ${request.statusText}`,
      );
    }

    const res = (await request.json()) as any;

    return res.data;
  }
}

type DatadogSearchBody = {
  data: {
    type: string;
    attributes: {
      from: number;
      to: number;
      queries: [
        {
          data_source: string;
          query: string;
          name: string;
        },
      ];
      formulas: [
        {
          formula: string;
          limit: {
            count: number;
            order: string;
          };
        },
      ];
    };
  };
};
