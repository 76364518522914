import {
    createApiRef,
    DiscoveryApi,
    IdentityApi,
    FetchApi,
} from '@backstage/core-plugin-api';
import qs from 'qs';
import { Flag } from '../hooks';

export const launchDarklyApiRef = createApiRef<LaunchDarklyApi>({
    id: 'plugin.ld-flag-monitor.ld.service',
});

export type FlagFilters = {
    env?: string;
    archived?: boolean;
    sort?: string;
    expand?: string;
};

export class LaunchDarklyApi {
    private readonly discoveryApi: DiscoveryApi;
    private readonly fetchApi: FetchApi;
    private readonly identityApi: IdentityApi;
    private apiUrl: string | null;

    constructor({ discoveryApi, fetchApi, identityApi }: { discoveryApi: DiscoveryApi, fetchApi: FetchApi, identityApi: IdentityApi }) {
        this.discoveryApi = discoveryApi;
        this.fetchApi = fetchApi;
        this.identityApi = identityApi;
        this.apiUrl = null;
    }

    async generateApiUrl(): Promise<Record<string, string>> {
        const baseUrl = await this.discoveryApi.getBaseUrl('proxy');

        return {
            baseUrl: `${baseUrl}/launchdarkly/api`,
        }
    }

    async getTeamFlags(teamKey: string, filters: FlagFilters = {}): Promise<Flag[]> {
        const { baseUrl } = await this.generateApiUrl();

        let url = `${baseUrl}/flags/${teamKey}`;
        const headers = {
            'Content-Type': 'application/json',
        };

        const query = qs.stringify(filters);

        if (Object.keys(filters).length > 0) {
            url = `${url}?${query}`;
        }

        return this.handleRequest('GET', url, { headers });
    }

    private async handleRequest(method: string, url: string, { headers, ...options }: Record<string, any>): Promise<any> {
        if (!this.apiUrl) {
            await this.generateApiUrl();
        }

        const { token } = await this.identityApi.getCredentials();

        if (!token) {
            throw new Error('User not authenticated');
        }

        const response: Response = await this.fetchApi.fetch(url, {
            method,
            headers: {
                ...headers,
                Authorization: `Bearer ${token}`
            },
            ...options
        });

        if (!response.ok) {
            throw new Error(
                `Failed to fetch data from launchdarkly, status ${response.status}: ${response.statusText}`,
            );
        }

        return await response.json();
    }
}
