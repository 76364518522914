import { secApiRef, SecOpsFindings } from './api';
import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const secOpsCompliancePlugin = createPlugin({
  id: 'secops-compliance',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: secApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        configApi: configApiRef,
        identityApi: identityApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, identityApi, fetchApi }) => {
        return new SecOpsFindings({
          discoveryApi,
          identityApi,
          fetchApi,
        });
      },
    }),
  ],
});

export const SecOpsComplianceWidget = secOpsCompliancePlugin.provide(
  createRoutableExtension({
    name: 'SecOpsCompliancePage',
    component: () =>
      import('./components/SecOpsComponent').then(m => m.SecOpsComponent),
    mountPoint: rootRouteRef,
  }),
);
