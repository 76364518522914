import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 60,
  },
  primaryText: {
    fill: '#ffffff',
  },
  primaryFill: {
    fill: '#7a00e6',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
    className={classes.svg}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2079.95 456.05"
    >
      <g>
        <g>
          <path
            d="m350.59005,260.28421h11.41806c3.23651,0,5.84764.95419,7.83339,2.86257,1.98575,1.91483,2.97862,4.36478,2.97862,7.34985,0,1.54089-.27723,2.95928-.83169,4.25518-.55446,1.28945-1.30879,2.39192-2.26298,3.30099-.95419.90906-2.09535,1.61181-3.41704,2.1018-1.32813.49644-2.75942.74143-4.30031.74143h-6.97591v12.66237h-4.44215v-33.27419Zm11.41806,3.9715h-6.97591v12.66882h6.97591c1.90838,0,3.43638-.56736,4.57754-1.68918,1.13471-1.12182,1.70852-2.70139,1.70852-4.73872,0-1.97285-.59315-3.50085-1.77944-4.59688-1.18629-1.09603-2.6885-1.64405-4.50662-1.64405Z"
            className={classes.primaryText}
          />
          <path
            d="m387.14588,269.8519c1.59891,0,3.09467.29657,4.48083.90261,1.38616.59959,2.57245,1.43129,3.55887,2.49508s1.76654,2.34035,2.3339,3.83611c.5738,1.48931.85748,3.11402.85748,4.87411,0,1.69562-.29657,3.28164-.87682,4.75806-.5867,1.48286-1.38616,2.76586-2.40482,3.86189-1.01866,1.08958-2.2114,1.94707-3.58466,2.55955-1.36681.61893-2.82389.9284-4.36478.9284-1.59891,0-3.09467-.30302-4.48083-.90261s-2.57245-1.43773-3.55887-2.52087c-.98643-1.07669-1.76654-2.35324-2.3339-3.83611-.5738-1.47642-.85748-3.09467-.85748-4.84832,0-1.72786.29657-3.32677.87682-4.80964.5867-1.47642,1.38616-2.75297,2.40482-3.83611,1.01866-1.07669,2.2114-1.92128,3.58466-2.54021,1.36681-.61249,2.82389-.92195,4.36478-.92195Zm0,20.42485c1.90838,0,3.55887-.72854,4.94503-2.19206,1.38616-1.46352,2.08246-3.5073,2.08246-6.12487,0-1.32813-.19342-2.51442-.58025-3.55887-.38683-1.0509-.89617-1.92772-1.528-2.63692-.63183-.7092-1.37326-1.24432-2.23719-1.61826-.86393-.36749-1.7601-.55446-2.68205-.55446-1.90838,0-3.55887.74143-4.94503,2.21785-1.38616,1.48286-2.07601,3.53309-2.07601,6.15066,0,1.32168.18697,2.50153.5738,3.53309.38683,1.03156.89617,1.90193,1.528,2.61113.63183.7092,1.37326,1.25076,2.23719,1.61826s1.7601.55446,2.68205.55446Z"
            className={classes.primaryText}
          />
          <path
            d="m435.07464,270.36124l-7.11775,23.19716h-4.99016l-4.11334-16.40822-.68985-3.23007-.74143,3.23007-4.11334,16.40822h-4.99016l-7.11775-23.19716h4.34544l4.61622,16.22125.6963,3.1398.59959-3.1398,4.15847-16.22125h5.08687l4.11334,16.22125.64472,3.23007.6963-3.23007,4.57109-16.22125h4.34544Z"
            className={classes.primaryText}
          />
          <path
            d="m448.84594,269.8519c1.47642,0,2.84968.27078,4.11334.8059,1.26366.54157,2.34679,1.27011,3.25585,2.19851.90906.92195,1.61826,2.02443,2.12759,3.30099.50933,1.283.76077,2.64337.76077,4.094,0,.39973-.01289.81235-.04513,1.24432-.03224.43196-.05803.83169-.09026,1.20563h-16.86597c.1225,2.49508.86393,4.38412,2.23719,5.68002,1.37326,1.29589,3.0302,1.94062,4.97082,1.94062,1.32168,0,2.51442-.25144,3.57822-.76077,1.06379-.50933,2.02443-1.28945,2.88836-2.3339l2.77231,2.35969c-1.14116,1.50865-2.50798,2.63047-4.11334,3.3719-1.59891.74143-3.35901,1.10892-5.26739,1.10892-1.56668,0-3.04954-.27723-4.4357-.83169-1.38616-.55446-2.57889-1.34747-3.57822-2.37903-1.00577-1.03156-1.79878-2.30166-2.38548-3.81676-.58025-1.50865-.87682-3.18493-.87682-5.03529,0-1.72786.28368-3.33322.85748-4.82898.56736-1.49576,1.34747-2.77876,2.3339-3.86189.98643-1.07669,2.14693-1.92128,3.48796-2.54021,1.34103-.61249,2.76586-.92195,4.27452-.92195Zm0,3.60401c-1.54089,0-2.9206.50933-4.13268,1.52155-1.21853,1.01866-2.01154,2.43706-2.38548,4.25518h12.61724c-.1225-1.63115-.73498-3.00441-1.84391-4.11334-1.11537-1.10892-2.52732-1.66339-4.25518-1.66339Z"
            className={classes.primaryText}
          />
          <path
            d="m464.64811,293.55839v-23.19716h3.97795v3.7394c.83169-1.38616,1.8568-2.43706,3.07533-3.16559,1.21208-.72209,2.59179-1.08314,4.13268-1.08314.24499,0,.50288.01289.76077.04513.26434.03224.47065.06447.62538.09026l-.68985,4.30031c-.25144-.06447-.50933-.1096-.78656-.14184-.27723-.02579-.5738-.04513-.87682-.04513-1.85036,0-3.32033.61893-4.41636,1.85036-1.09603,1.23142-1.6376,2.89481-1.6376,4.99016v12.61724h-4.16491Z"
            className={classes.primaryText}
          />
          <path
            d="m490.29522,269.8519c1.47642,0,2.84968.27078,4.11334.8059,1.26366.54157,2.34679,1.27011,3.25585,2.19851.90906.92195,1.61826,2.02443,2.12759,3.30099.50933,1.283.76077,2.64337.76077,4.094,0,.39973-.01289.81235-.04513,1.24432s-.05803.83169-.09026,1.20563h-16.86597c.1225,2.49508.86393,4.38412,2.23719,5.68002,1.37326,1.29589,3.0302,1.94062,4.97082,1.94062,1.32168,0,2.51442-.25144,3.57822-.76077,1.06379-.50933,2.02443-1.28945,2.88836-2.3339l2.77231,2.35969c-1.14116,1.50865-2.50798,2.63047-4.11334,3.3719-1.59891.74143-3.35901,1.10892-5.26739,1.10892-1.56668,0-3.04954-.27723-4.4357-.83169-1.38616-.55446-2.57889-1.34747-3.57822-2.37903-1.00577-1.03156-1.79878-2.30166-2.38548-3.81676-.58025-1.50865-.87682-3.18493-.87682-5.03529,0-1.72786.28368-3.33322.85748-4.82898.56736-1.49576,1.34747-2.77876,2.3339-3.86189.98643-1.07669,2.14693-1.92128,3.48796-2.54021,1.34103-.61249,2.76586-.92195,4.27452-.92195Zm0,3.60401c-1.54089,0-2.9206.50933-4.13268,1.52155-1.21853,1.01866-2.01154,2.43706-2.38548,4.25518h12.61724c-.1225-1.63115-.73498-3.00441-1.84391-4.11334-1.11537-1.10892-2.52732-1.66339-4.25518-1.66339Z"
            className={classes.primaryText}
          />
          <path
            d="m515.61997,294.06773c-1.54089,0-2.96573-.30302-4.27452-.90261-1.30879-.59959-2.43706-1.43773-3.3719-2.52087-.9413-1.07669-1.68273-2.35324-2.21785-3.83611-.54157-1.47642-.81235-3.09467-.81235-4.84832,0-1.69562.28368-3.28164.85748-4.75806.56736-1.48286,1.34747-2.76586,2.3339-3.86189s2.14048-1.94707,3.46217-2.566c1.32813-.61249,2.72718-.92195,4.21005-.92195,1.54089,0,2.93994.33526,4.2036,1.01866,1.26366.67696,2.32745,1.63115,3.19138,2.86257v-13.44894h4.2036v25.92434c0,1.23142.08381,2.27587.25144,3.12046.17408.85104.51578,1.96641,1.04445,3.35256l-3.88768,1.19919c-.30302-.7092-.56091-1.42484-.76077-2.14693-.19986-.72209-.34815-1.46997-.43841-2.24364-.89617,1.45063-2.03088,2.57245-3.41704,3.3719-1.38616.8059-2.91415,1.20563-4.57754,1.20563Zm.55446-3.74584c1.90838,0,3.55887-.73498,4.94503-2.21785,1.38616-1.47642,2.08246-3.52664,2.08246-6.14422,0-1.32813-.19342-2.50153-.58025-3.53953-.38683-1.03156-.89617-1.90193-1.528-2.61113-.63183-.70275-1.36681-1.25076-2.21785-1.6376-.84459-.38683-1.72786-.58025-2.65626-.58025-1.94062,0-3.60401.74143-4.99016,2.21785-1.38616,1.48286-2.07601,3.53309-2.07601,6.15066,0,1.32168.18697,2.50153.5738,3.53309.38683,1.03156.90261,1.90193,1.54734,2.61113.65117.7092,1.3926,1.25721,2.24364,1.64405.84459.38039,1.73431.5738,2.65626.5738Z"
            className={classes.primaryText}
          />
          <path
            d="m556.42453,269.8519c1.50865,0,2.9206.29657,4.22939.90261,1.30879.59959,2.43706,1.43129,3.3719,2.49508.9413,1.06379,1.68273,2.34035,2.21785,3.83611.54157,1.48931.81235,3.11402.81235,4.87411,0,1.69562-.28368,3.28164-.85748,4.75806-.56736,1.48286-1.34747,2.76586-2.3339,3.86189-.98643,1.08958-2.14048,1.94707-3.46217,2.55955-1.32813.61893-2.72718.9284-4.21005.9284-1.56668,0-3.01086-.3546-4.31965-1.06379s-2.39192-1.72786-3.25585-3.04954v3.60401h-3.97795v-33.27419h4.16491v13.58433c.88972-1.25721,1.99864-2.24364,3.32677-2.95283,1.32168-.7092,2.75297-1.06379,4.29386-1.06379Zm-.59959,3.7394c-1.90838,0-3.55887.74143-4.94503,2.21785-1.38616,1.48286-2.07601,3.53309-2.07601,6.15066,0,1.32168.18697,2.50153.5738,3.53309.38683,1.03156.89617,1.90193,1.528,2.61113.63183.7092,1.37971,1.25721,2.23719,1.64405.86393.38039,1.7601.5738,2.68205.5738,1.90838,0,3.55887-.73498,4.94503-2.21785,1.38616-1.47642,2.08246-3.52664,2.08246-6.14422,0-1.32813-.19342-2.50153-.58025-3.53953-.38683-1.03156-.89617-1.90193-1.528-2.61113-.63183-.70275-1.37326-1.25076-2.23719-1.6376-.86393-.38683-1.7601-.58025-2.68205-.58025Z"
            className={classes.primaryText}
          />
          <path
            d="m592.47747,270.36124l-9.93519,25.87921c-.98643,2.55311-2.06956,4.37123-3.23651,5.45436-1.1734,1.07669-2.72718,1.61181-4.6678,1.61181-.61893,0-1.25721-.06447-1.92128-.20631-.65762-.13539-1.32813-.36105-2.00509-.67051l1.28945-3.51374c.4642.25144.90906.42552,1.34103.53512.43196.1096.85104.16118,1.25076.16118.88972,0,1.61826-.19986,2.17272-.59959s1.01222-1.08314,1.38616-2.03733l1.05735-2.77231-9.88361-23.84188h4.71293l6.23448,15.84731,1.06379,3.04954.97353-3.04954,5.73159-15.84731h4.4357Z"
            className={classes.primaryText}
          />
        </g>
        <g>
          <path
            d="m698.55352,253.94911c-5.52709-2.09362-10.71921-3.09855-16.49752-3.09855-12.64531,0-21.52215,8.4581-21.52215,21.77339,0,13.48272,7.70443,21.77339,21.35465,21.77339,5.52709,0,10.88671-.92124,16.66502-3.34977,1.33988-.58622,2.09359-1.50738,2.09359-3.34977v-30.39896c0-1.9261-.75371-2.84728-2.09359-3.34972Zm-8.79309,27.38416c0,1.25616-.66996,1.8424-2.09362,2.26112-1.84235.50247-3.43347.75369-5.69456.75369-5.35959,0-10.38422-3.18228-10.38422-11.72412s5.02462-11.72412,10.38422-11.72412c2.26109,0,3.85222.25122,5.69456.75369,1.42365.41872,2.09362,1.00491,2.09362,2.26107v17.41868Z"
            className={classes.primaryText}
          />
          <path
            d="m628.04129,264.8358c0-2.56359,2.59606-4.18719,7.03447-4.18719,4.27093,0,8.3744,1.42363,12.64531,3.76842.41872.2513.83744.33497,1.25616.33497.75369,0,1.47059-.47816,1.84235-1.17238l2.59606-5.52709c.24179-.51477.33497-1.00493.33497-1.42363,0-.66996-.35007-1.29228-1.00491-1.67487-5.19212-3.0148-11.38915-4.43843-17.33496-4.43843-11.22163,0-18.42359,6.02956-18.42359,15.07385,0,17.75373,26.96546,10.88155,26.96546,23.19707,0,1.25613-.16747,2.00982-.50244,3.09848-.04959.16149-.08377.33497-.08377.50247,0,.66994.41874,1.17241,1.25616,1.17241h7.53694c1.25616,0,1.75862-.41872,2.17734-1.67487.50247-1.42363.66996-3.34975.66996-4.7734,0-19.00981-26.96549-14.43338-26.96549-22.27579Z"
            className={classes.primaryText}
          />
          <path
            d="m729.78992,250.85056c-4.94087,0-10.71921.83744-16.83249,3.0148-1.17241.41872-2.09359,1.42363-2.09359,3.34972v34.6699c0,1.00493.66994,1.67487,1.67487,1.67487h7.53694c1.00493,0,1.67487-.66994,1.67487-1.67487v-28.1379c0-1.25613.66996-1.84235,2.00987-2.17732,2.17732-.50247,3.60097-.66994,6.19703-.66994,4.52216,0,8.12313,2.09357,8.12313,7.11819v23.86696c0,1.00493.66996,1.67487,1.67487,1.67487h7.53694c1.00493,0,1.67487-.66994,1.67487-1.67487v-25.29059c0-10.21674-6.28078-15.74384-19.17731-15.74384Z"
            className={classes.primaryText}
          />
          <path
            d="m777.77511,250.85056c-12.56161,0-20.76851,9.12809-20.76851,21.77339s8.2069,21.77339,21.35465,21.77339c12.56161,0,20.76848-9.12811,20.76848-21.77339s-8.20688-21.77339-21.35462-21.77339Zm.58614,33.49751c-6.19701,0-10.30049-4.01971-10.30049-11.72412s4.10349-11.72412,9.71435-11.72412c6.19701,0,10.30044,4.01966,10.30044,11.72412s-4.10344,11.72412-9.7143,11.72412Z"
            className={classes.primaryText}
          />
          <rect
            x="836.56312"
            y="251.688"
            width="10.88673"
            height="41.87186"
            rx="1.67485"
            ry="1.67485"
            className={classes.primaryText}
          />
          <path
            d="m829.69617,235.52544c-1.67487-.41867-3.49021-.58614-5.27582-.58614-10.38424,0-17.16749,5.52704-17.16749,17.58613v39.35955c0,1.00493.66999,1.67487,1.67487,1.67487h7.45314c1.00498,0,1.67492-.66994,1.67492-1.67487v-30.98515h10.63544c1.08868,0,1.67487-.66994,1.67487-1.67487v-5.77831c0-1.08871-.58619-1.75865-1.67487-1.75865h-10.63544v-.92116c0-4.60593,2.00982-6.95074,6.61575-6.95074,1.67487,0,3.47899.41872,4.10344.41872,1.00493,0,1.42363-.58619,1.59113-1.5911l.83749-4.35468c.07422-.3861.1675-.72581.1675-1.08866,0-.93049-.50249-1.42365-1.67492-1.67492Z"
            className={classes.primaryText}
          />
          <path
            d="m622.05363,282.8407c-3.4335,0-5.69459,2.17732-5.69459,5.77831,0,3.51722,2.26109,5.77834,5.94581,5.77834,3.4335,0,5.69456-2.26112,5.69456-5.77834,0-3.601-2.26107-5.77831-5.94578-5.77831Z"
            className={classes.primaryText}
          />
          <path
            d="m841.88088,234.95492c-3.4335,0-5.69459,2.17732-5.69459,5.77831,0,3.51722,2.26109,5.77834,5.94581,5.77834,3.4335,0,5.69456-2.26112,5.69456-5.77834,0-3.601-2.26107-5.77831-5.94578-5.77831Z"
            className={classes.primaryText}
          />
        </g>
      </g>
      <g>
        <path
          d="m107.17463,96.05375h14.73194l39.48288,109.32579h-15.18969l-10.47676-29.76044h-43.11912l-10.32847,29.76044h-14.42247l39.3217-109.32579Zm2.12759,31.7333l-12.14659,34.92468h34.16391l-12.29488-34.92468-4.86122-15.33798-4.86122,15.33798Z"
          className={classes.primaryText}
        />
        <path
          d="m206.93917,194.59976c4.75806,0,9.01324-1.3926,12.75908-4.17781,3.7394-2.78521,6.57618-6.70513,8.50391-11.76621l12.14659,5.0095c-2.83678,6.98236-7.26604,12.63014-13.28775,16.93044-6.02172,4.30675-12.77842,6.45368-20.27011,6.45368-5.46726,0-10.45742-.98643-14.95759-2.95928-4.50662-1.97285-8.34917-4.73227-11.54055-8.27825-3.19138-3.53953-5.64778-7.74313-7.36274-12.60435-1.72141-4.85477-2.58534-10.17374-2.58534-15.94402,0-5.56397.93485-10.77978,2.811-15.63455,1.8697-4.86122,4.42925-9.05837,7.66576-12.60435,3.24296-3.54598,7.08551-6.35053,11.54055-8.42654,4.45504-2.07601,9.2131-3.11402,14.27418-3.11402,7.79471,0,14.5772,2.07601,20.34748,6.22158,5.77028,4.15202,10.02545,9.87072,12.75908,17.16254l-12.60435,5.31252c-1.6247-5.16424-4.3261-9.16152-8.12352-11.99186-3.79742-2.83678-7.92365-4.25518-12.37869-4.25518-6.27961,0-11.59213,2.43061-15.94402,7.29182-4.35188,4.85477-6.5246,11.54055-6.5246,20.03801,0,8.60706,2.19851,15.31863,6.60197,20.12183,4.40346,4.80964,9.79335,7.21446,16.16967,7.21446Z"
          className={classes.primaryText}
        />
        <path
          d="m289.39288,194.59976c4.75806,0,9.00679-1.3926,12.75263-4.17781,3.74584-2.78521,6.58263-6.70513,8.50391-11.76621l12.14659,5.0095c-2.83034,6.98236-7.26604,12.63014-13.28775,16.93044-6.02172,4.30675-12.77842,6.45368-20.27011,6.45368-5.46726,0-10.45097-.98643-14.95759-2.95928-4.50017-1.97285-8.34917-4.73227-11.54055-8.27825-3.18493-3.53953-5.64133-7.74313-7.36274-12.60435-1.72141-4.85477-2.57889-10.17374-2.57889-15.94402,0-5.56397.93485-10.77978,2.811-15.63455,1.8697-4.86122,4.4228-9.05837,7.66576-12.60435,3.23651-3.54598,7.08551-6.35053,11.54055-8.42654,4.45504-2.07601,9.20665-3.11402,14.27418-3.11402,7.79471,0,14.5772,2.07601,20.34748,6.22158,5.77028,4.15202,10.01901,9.87072,12.75263,17.16254l-12.60435,5.31252c-1.61826-5.16424-4.3261-9.16152-8.12352-11.99186-3.79742-2.83678-7.92365-4.25518-12.37225-4.25518-6.27961,0-11.59213,2.43061-15.94402,7.29182-4.35833,4.85477-6.53105,11.54055-6.53105,20.03801,0,8.60706,2.20495,15.31863,6.60842,20.12183,4.40346,4.80964,9.79335,7.21446,16.16967,7.21446Z"
          className={classes.primaryText}
        />
        <path
          d="m371.24055,127.48402c4.85477,0,9.36139.88327,13.51341,2.65626,4.14557,1.77299,7.71734,4.17781,10.70241,7.21446,2.98507,3.03665,5.31252,6.65355,6.9888,10.85715,1.66983,4.19715,2.50153,8.67798,2.50153,13.43604,0,1.31524-.05158,2.68205-.14829,4.10044-.10316,1.41839-.20631,2.73363-.30302,3.94571h-55.42689c.40618,8.20088,2.85613,14.42892,7.36274,18.67764,4.50662,4.25518,9.94809,6.37632,16.3244,6.37632,4.35188,0,8.27825-.83169,11.76621-2.50153,3.4944-1.66983,6.66-4.22939,9.49033-7.67221l9.11639,7.74958c-3.75229,4.95792-8.25246,8.65219-13.51986,11.0828-5.26095,2.43061-11.03122,3.64269-17.31083,3.64269-5.15779,0-10.01901-.90906-14.5772-2.73363-4.55175-1.81812-8.47812-4.42925-11.76621-7.8205-3.28809-3.39125-5.89922-7.56261-7.8205-12.52698-1.92128-4.95792-2.88191-10.47676-2.88191-16.55006,0-5.66712.93485-10.95386,2.811-15.86665,1.8697-4.91279,4.4228-9.13573,7.66576-12.68171,3.23651-3.53953,7.05972-6.32474,11.46319-8.34917,4.40346-2.02443,9.08416-3.03665,14.04853-3.03665Zm0,11.84357c-5.06753,0-9.59349,1.66983-13.59077,5.0095-4.00373,3.33967-6.60842,8.00102-7.8205,13.97116h41.44928c-.39973-5.3641-2.42416-9.87072-6.0733-13.51341-3.64269-3.64269-8.29759-5.46726-13.96471-5.46726Z"
          className={classes.primaryText}
        />
        <path
          d="m436.37698,181.08635c0,4.15202.30302,7.63998.90906,10.47676.61249,2.83678,1.72141,6.42789,3.34612,10.77978l-13.21039,4.10044c-1.6247-3.54598-2.83678-7.29182-3.64914-11.23753-.81235-3.94571-1.21208-8.34917-1.21208-13.21039v-85.94166h13.81643v85.0326Z"
          className={classes.primaryText}
        />
        <path
          d="m490.58535,127.48402c4.86122,0,9.36139.88327,13.51341,2.65626,4.15202,1.77299,7.71734,4.17781,10.70241,7.21446,2.98507,3.03665,5.31897,6.65355,6.9888,10.85715,1.66983,4.19715,2.50153,8.67798,2.50153,13.43604,0,1.31524-.05158,2.68205-.14829,4.10044-.10316,1.41839-.20631,2.73363-.30302,3.94571h-55.42045c.39973,8.20088,2.85613,14.42892,7.36274,18.67764,4.50017,4.25518,9.94164,6.37632,16.3244,6.37632,4.35188,0,8.2718-.83169,11.76621-2.50153,3.4944-1.66983,6.65355-4.22939,9.49033-7.67221l9.10995,7.74958c-3.74584,4.95792-8.25246,8.65219-13.51986,11.0828-5.26095,2.43061-11.03122,3.64269-17.30438,3.64269-5.16424,0-10.01901-.90906-14.5772-2.73363-4.5582-1.81812-8.47812-4.42925-11.76621-7.8205-3.29454-3.39125-5.89922-7.56261-7.8205-12.52698-1.92772-4.95792-2.88836-10.47676-2.88836-16.55006,0-5.66712.93485-10.95386,2.811-15.86665,1.8697-4.91279,4.42925-9.13573,7.66576-12.68171,3.24296-3.53953,7.06617-6.32474,11.46963-8.34917s9.08416-3.03665,14.04208-3.03665Zm0,11.84357c-5.06108,0-9.59349,1.66983-13.59077,5.0095-3.99729,3.33967-6.60197,8.00102-7.8205,13.97116h41.45573c-.40618-5.3641-2.43061-9.87072-6.0733-13.51341-3.64269-3.64269-8.30404-5.46726-13.97116-5.46726Z"
          className={classes.primaryText}
        />
        <path
          d="m542.51139,205.37954v-76.22568h13.05565v12.30133c2.73363-4.5582,6.09909-8.02681,10.10282-10.40584,3.99729-2.37258,8.52325-3.56532,13.58433-3.56532.81235,0,1.64405.05158,2.50798.14829.85748.10316,1.54089.20631,2.05022.30302l-2.27587,14.1259c-.81235-.19986-1.66983-.3546-2.58534-.45775-.90906-.09671-1.8697-.14829-2.88191-.14829-6.0733,0-10.90872,2.02443-14.49984,6.0733-3.59756,4.04886-5.38989,9.51612-5.38989,16.39532v41.45573h-13.66814Z"
          className={classes.primaryText}
        />
        <path
          d="m623.14053,127.48402c9.9223,0,17.84595,2.811,23.76451,8.42654,5.92501,5.61554,8.88429,13.03631,8.88429,22.24297v23.08111c0,4.04886.30302,7.4659.90906,10.25111.61249,2.78521,1.72141,6.45368,3.34612,11.01188l-12.45606,3.94571c-.90906-2.02443-1.66983-4.04886-2.27587-6.0733-.60604-2.02443-1.11537-4.15202-1.5151-6.37632-2.83678,4.35188-6.53105,7.61419-11.08925,9.79335-4.55175,2.17272-9.41297,3.2623-14.5772,3.2623-7.5884,0-13.73906-2.07601-18.44554-6.22803-4.70648-4.14557-7.06617-9.30981-7.06617-15.48626,0-6.27316,2.15338-11.51476,6.45368-15.71192,4.30675-4.2036,10.80557-6.96302,19.51579-8.27825,3.33967-.50288,6.95657-.85748,10.85715-1.06379,3.89413-.19986,8.1751-.25144,12.83-.14829v-1.67628c0-5.66712-1.7472-10.19308-5.2416-13.58433-3.48796-3.39125-8.22023-5.08687-14.19682-5.08687-4.04886,0-7.74313.85748-11.0828,2.57889s-6.42789,4.35188-9.26468,7.89786l-10.17374-7.74958c3.64914-4.6549,8.12352-8.32338,13.44249-11.00543,5.31252-2.68205,11.10859-4.02308,17.38175-4.02308Zm-16.85308,57.09028c0,3.1398,1.1605,5.69936,3.4944,7.67221,2.32745,1.97285,5.61554,2.95928,9.87072,2.95928,6.47303,0,11.86292-1.72141,16.16967-5.16424,4.30031-3.43638,6.45368-8.20088,6.45368-14.27418v-4.24873c-4.75806-.10316-9.05837-.07737-12.90737.07737-3.849.14829-7.3434.52867-10.47676,1.13471-4.35188.81235-7.54327,2.20495-9.5677,4.17781-2.02443,1.97285-3.03665,4.53241-3.03665,7.66576Z"
          className={classes.primaryText}
        />
        <path
          d="m667.32989,129.15386h15.78928v-21.55956h13.51341v21.55956h22.6298v11.84357h-22.6298v42.21006c0,3.849.88972,6.73736,2.65626,8.65864,1.77299,1.92128,4.53241,2.88191,8.27825,2.88191,1.72141,0,3.41704-.22565,5.08687-.68341,1.66983-.45131,3.41704-1.18629,5.2416-2.19851l4.40346,10.93451c-2.3339,1.41839-4.91279,2.48219-7.74958,3.18493-2.83034.7092-5.66712,1.06379-8.49746,1.06379-6.9888,0-12.60435-2.05022-16.85952-6.15066-4.24873-4.10044-6.37632-9.94164-6.37632-17.53648v-42.36479h-15.48626v-11.84357Z"
          className={classes.primaryText}
        />
        <path
          d="m767.54574,127.48402c5.26095,0,10.17374.98643,14.73194,2.95928,4.55175,1.97285,8.45233,4.70648,11.68884,8.20088,3.24296,3.4944,5.79607,7.69155,7.67221,12.60435,1.8697,4.90635,2.80455,10.24466,2.80455,16.01494,0,5.57041-.96064,10.78623-2.88191,15.641-1.92772,4.86122-4.5582,9.0906-7.89786,12.68171-3.33967,3.59111-7.26604,6.40211-11.76621,8.42654-4.50662,2.02443-9.29047,3.03665-14.35155,3.03665-5.26095,0-10.17374-.98643-14.72549-2.95928-4.5582-1.97285-8.45877-4.73227-11.69529-8.27825-3.24296-3.53953-5.79607-7.74313-7.66576-12.60435-1.87615-4.85477-2.811-10.17374-2.811-15.94402,0-5.66712.96064-10.92807,2.88836-15.78928,1.92128-4.86122,4.55175-9.05837,7.89142-12.60435,3.33967-3.53953,7.26604-6.32474,11.77265-8.34917,4.50017-2.02443,9.28402-3.03665,14.3451-3.03665Zm0,67.11573c6.27961,0,11.69529-2.40482,16.24704-7.21446,4.5582-4.80319,6.83407-11.51476,6.83407-20.12183,0-4.34544-.63183-8.24602-1.89549-11.68884-1.27011-3.44282-2.93994-6.32474-5.01595-8.65864-2.07601-2.32745-4.52596-4.094-7.36274-5.31252-2.83678-1.21208-5.77028-1.81812-8.80693-1.81812-6.27961,0-11.68884,2.42416-16.24704,7.28538-4.55175,4.86122-6.83407,11.59213-6.83407,20.19275,0,4.35833.63183,8.22667,1.90193,11.61792,1.26366,3.39125,2.93349,6.25382,5.0095,8.58127,2.07601,2.32745,4.52596,4.10044,7.36274,5.31252,2.83678,1.21853,5.77028,1.82457,8.80693,1.82457Z"
          className={classes.primaryText}
        />
        <path
          d="m823.12092,205.37954v-76.22568h13.05565v12.30133c2.73363-4.5582,6.09909-8.02681,10.10282-10.40584,3.99729-2.37258,8.52325-3.56532,13.58433-3.56532.81235,0,1.64405.05158,2.50798.14829.85748.10316,1.54089.20631,2.05022.30302l-2.27587,14.1259c-.81235-.19986-1.66983-.3546-2.58534-.45775-.90906-.09671-1.8697-.14829-2.88191-.14829-6.0733,0-10.90872,2.02443-14.49984,6.0733-3.59756,4.04886-5.38989,9.51612-5.38989,16.39532v41.45573h-13.66814Z"
          className={classes.primaryText}
        />
      </g>
      <g>
        <path
          d="m1057.94405,240.02688l-85.45842,49.33991c-4.77913,2.75915-10.12713-2.58906-7.36798-7.36798l92.8264-160.77522,68.98713,119.48583c2.95821-10.83096,4.51687-22.64756,4.51687-35.33149,0-68.31512-42.89524-109.62141-112.79864-109.62141-65.13766,0-108.03334,41.30629-108.03334,109.62141,0,66.72595,42.89568,109.62206,112.7993,109.62206,39.58259,0,70.92657-15.86293,89.44769-43.26526l-54.91901-31.70786Z"
          className={classes.primaryFill}
        />
        <path
          d="m984.70978,269.77445l73.23427-42.28095,60.48647,34.92131c1.37519-2.61913,2.65249-5.31837,3.81918-8.10517l-64.30566-111.38461-73.23427,126.84942Z"
          className={classes.primaryFill}
        />
      </g>
    </svg>
  );
};

export default LogoFull;
