import React, { Fragment } from 'react';
import { InfoCard, Progress } from '@backstage/core-components';
import { Grid, Theme, Typography, makeStyles } from '@material-ui/core';
import {
  Node,
  Repository,
} from '@internal/plugin-sanofi-dependabot-monitoring';

type Criticality = {
  [key: string]: Node[];
};

const DAY_IN_MS = 1000 * 60 * 60 * 24;

export const dependabotColourStyles = makeStyles<Theme>((theme: Theme) => ({
  critical: {
    color: theme.palette.type === 'dark' ? '#f85149' : '#cf222e',
  },
  high: {
    color: theme.palette.type === 'dark' ? '#db6d28' : '#bc4c00',
  },
  moderate: {
    color: theme.palette.type === 'dark' ? '#d29922' : '#bf8600',
  },
  low: {
    color: theme.palette.type === 'dark' ? '#c9d1d9' : '#24292f',
  },
}));

const CriticalityRows = ({ data }: { data: Repository }) => {
  const dateMinusSevenDays = new Date(new Date().getTime() - DAY_IN_MS * 7);
  const sum: Criticality = {
    critical: [],
    high: [],
    moderate: [],
    low: [],
  };
  data.vulnerabilityAlerts.nodes.forEach((node: Node) => {
    sum[(node.securityVulnerability.severity as string).toLowerCase()].push(
      node,
    );
  });
  const style = dependabotColourStyles();
  return (
    <Fragment>
      {Object.keys(sum).map((category: string) => {
        if (sum[category].length === 0) {
          return null;
        }

        const totalVulnerabilities = sum[category].length;
        let totalNewVulnerabilities = 0;
        sum[category].forEach((node: Node) => {
          const isNewVulnerability =
            new Date(node.createdAt) > dateMinusSevenDays;
          if (isNewVulnerability) {
            totalNewVulnerabilities++;
          }
        });

        return (
          <Grid item key={category}>
            <Typography variant="h4" className={style[category]}>
              {totalVulnerabilities} {category}
            </Typography>
            {totalNewVulnerabilities > 0 && (
              <Typography variant="body1">
                +{totalNewVulnerabilities} in the past 7 days
              </Typography>
            )}
          </Grid>
        );
      })}
    </Fragment>
  );
};
export const SecurityGroupCard = ({
  result,
  teamName,
}: {
  result: { value?: any; loading: boolean; error?: any };
  teamName: string;
}) => {
  if (result.loading) {
    return (
      <InfoCard title="Dependabot">
        <Grid item xs={12}>
          <Progress />
        </Grid>
      </InfoCard>
    );
  }

  if (!result.value?.data?.vulnerabilityAlerts.totalCount) {
    return (
      <InfoCard title="Dependabot">
        <Grid item xs={12}>
          <Typography variant="body1">
            <b>No alert for {teamName}</b>
          </Typography>
        </Grid>
      </InfoCard>
    );
  }

  return (
    <InfoCard
      title="Dependabot"
      deepLink={{
        title: 'View security alerts',
        link: `/catalog/default/Group/${teamName}/security`,
      }}
    >
      <Grid container direction="row" justifyContent="space-evenly">
        <CriticalityRows data={result.value.data} />
      </Grid>
    </InfoCard>
  );
};
