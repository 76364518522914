import React, { useCallback } from 'react';
import {
  alertApiRef,
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  EntityRefLinks,
  catalogApiRef,
  getEntityRelations,
  useEntity,
} from '@backstage/plugin-catalog-react';
import { useEntityPermission } from '@backstage/plugin-catalog-react/alpha';
import { catalogEntityRefreshPermission } from '@backstage/plugin-catalog-common/alpha';
import {
  ANNOTATION_EDIT_URL,
  ANNOTATION_LOCATION,
  GroupEntity,
  RELATION_CHILD_OF,
  RELATION_PARENT_OF,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import {
  Avatar,
  InfoCard,
  InfoCardVariants,
  Link,
} from '@backstage/core-components';
import {
  Grid,
  Tooltip,
  Box,
  IconButton,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/Edit';
import GroupIcon from '@material-ui/icons/Group';
import CachedIcon from '@material-ui/icons/Cached';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ConnectWithoutContactIcon from '@material-ui/icons/DeveloperBoard';
import PersonIcon from '@material-ui/icons/Person';
import { SlackIcon, JiraIcon } from '../svgs';
import {
  Annotation,
  ConfigureEntityDialog,
} from '../../dialog/ConfigureEntity';
import useAsync from 'react-use/esm/useAsync';

const SLACK_CHANNELS_URL = 'https://sanofiaccelerator.slack.com/channels';
const JIRA_BROWSE_URL = 'https://sanofi.atlassian.net/browse';
const ANNOTATION_JIRA_PROJECT_KEY: string = 'jira/project-key';
const ANNOTATION_TEAM_SLACK_CHANNEL: string = 'slack/team-channel';
const ANNOTATION_TEAM_LEADS: string = 'github/team-admins';

const useStyles = makeStyles({
  teamInformation: {
    paddingTop: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  communicationChannels: {
    paddingRight: 10,
  },
});

const CardTitle = (props: { title: string }) => (
  <Box display="flex" alignItems="center">
    <GroupIcon fontSize="inherit" />
    <Box ml={1}>{props.title}</Box>
  </Box>
);

const splitTeamLeads = (teamLeads: string): string[] => {
  return teamLeads.split(',');
};

const createLinkForTeamLeadName = (teamLeadName: string): React.ReactNode => {
  return (
    <Link
      to={`/catalog/default/user/${teamLeadName}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {teamLeadName}
    </Link>
  );
};

const createTeamLeadLinks = (teamLeads: string): React.ReactNode => {
  if (!teamLeads) {
    return 'N/A';
  }

  const teamLeadNames = splitTeamLeads(teamLeads);

  return teamLeadNames.map((teamLeadName, index) => (
    <span key={teamLeadName}>
      {createLinkForTeamLeadName(teamLeadName)}
      {index < teamLeadNames.length - 1 && ', '}
    </span>
  ));
};

export const EntityGroupSummaryCard = (props: {
  variant?: InfoCardVariants;
}) => {
  const catalogApi = useApi(catalogApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);
  const alertApi = useApi(alertApiRef);
  const styles = useStyles();

  const { entity: group } = useEntity<GroupEntity>();
  const { allowed: canRefresh } = useEntityPermission(
    catalogEntityRefreshPermission,
  );

  const { value: configuration } = useAsync(async () => {
    const baseUrl = await discoveryApi.getBaseUrl(
      'external-entity-configuration',
    );
    const response = await fetchApi.fetch(
      `${baseUrl}/${group.kind}/configurations`,
    );
    const result = await response.json();
    return result;
  });

  const configurableAnnotations = configuration?.configurationAnnotations ?? [];

  const refreshEntity = useCallback(async () => {
    await catalogApi.refreshEntity(stringifyEntityRef(group));
    alertApi.post({
      message: 'Refresh scheduled',
      severity: 'info',
      display: 'transient',
    });
  }, [catalogApi, alertApi, group]);

  const handleUpdateConfigurableAnnotations = async (
    annotations: Annotation[],
  ) => {
    const baseUrl = await discoveryApi.getBaseUrl(
      'external-entity-configuration',
    );
    const generateBody = annotations.reduce(
      (acc: Record<string, string>, annotation: Annotation) => {
        if (configurableAnnotations.includes(annotation.key)) {
          acc[annotation.key] = annotation.value;
        }
        return acc;
      },
      {},
    );

    const res = await fetchApi.fetch(
      `${baseUrl}/${group.kind}/${group.metadata.namespace}/${group.metadata.name}/configure`,
      {
        method: 'POST',
        body: JSON.stringify({ annotations: generateBody }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    const body = await res.json();
    if (!res.ok) {
      await alertApi.post({
        message: body.error.message,
        severity: 'error',
        display: 'transient',
      });
      throw new Error();
    }
    await alertApi.post({
      message:
        'Metadata update, your changes will be reflected next time the entity is fetched.',
      severity: 'success',
      display: 'transient',
    });
    await catalogApi.refreshEntity(stringifyEntityRef(group));
  };

  if (!group) {
    return <Alert severity="error">Group not found</Alert>;
  }

  const {
    metadata: { name, description, title, annotations },
    spec: { profile },
  } = group;

  const teamLeads = annotations?.[ANNOTATION_TEAM_LEADS];
  const slackGroup = annotations?.[ANNOTATION_TEAM_SLACK_CHANNEL];
  const jiraGroup = annotations?.[ANNOTATION_JIRA_PROJECT_KEY];
  const jiraUrl = `${JIRA_BROWSE_URL}/${jiraGroup}`;
  const slackUrl = `${SLACK_CHANNELS_URL}/${slackGroup}`;
  const childRelations = getEntityRelations(group, RELATION_PARENT_OF, {
    kind: 'Group',
  });
  const parentRelations = getEntityRelations(group, RELATION_CHILD_OF, {
    kind: 'Group',
  });

  const entityLocation = annotations?.[ANNOTATION_LOCATION];
  const allowRefresh =
    entityLocation?.startsWith('url:') || entityLocation?.startsWith('file:');

  const entityMetadataEditUrl =
    group.metadata.annotations?.[ANNOTATION_EDIT_URL];

  const displayName = profile?.displayName ?? title ?? name;
  const infoCardActions = entityMetadataEditUrl ? (
    <>
      <IconButton
        aria-label="Edit"
        title="Edit Metadata"
        component={Link}
        to={entityMetadataEditUrl}
        disabled={!entityMetadataEditUrl}
      >
        <EditIcon />
      </IconButton>
      <ConfigureEntityDialog
        configurableAnnotations={configurableAnnotations}
        entityAnnotations={annotations}
        title={displayName}
        handleSubmit={handleUpdateConfigurableAnnotations}
        // Key to force re-render of the dialog when the entity is updated
        key={stringifyEntityRef(group)}
        kind={group.kind}
      />
    </>
  ) : (
    <IconButton aria-label="Edit" disabled title="Edit Metadata">
      <EditIcon />
    </IconButton>
  );

  return (
    <InfoCard
      title={<CardTitle title={displayName} />}
      variant={props.variant}
      action={
        <>
          {allowRefresh && canRefresh && (
            <IconButton
              aria-label="Refresh"
              title="Schedule entity refresh"
              onClick={refreshEntity}
            >
              <CachedIcon />
            </IconButton>
          )}
          {infoCardActions}
        </>
      }
    >
      <Grid container>
        <Grid item md={2}>
          <Avatar displayName={displayName} picture={profile?.picture} />
        </Grid>
        <Grid item container justifyContent="space-between" xs={10}>
          <ListItem>
            <ListItemIcon>
              <Tooltip title="Communication channels">
                <ConnectWithoutContactIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              primary={
                <Box display="flex">
                  <div className={styles.communicationChannels}>
                    <SlackIcon />
                    {slackGroup ? (
                      <Link to={slackUrl}>#{slackGroup}</Link>
                    ) : (
                      'N/A'
                    )}
                  </div>
                  <div>
                    <JiraIcon />
                    {jiraGroup ? (
                      <Link to={jiraUrl}>Jira: {jiraGroup}</Link>
                    ) : (
                      'N/A'
                    )}
                  </div>
                </Box>
              }
              secondary={<span>Communication Channels</span>}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Tooltip title="Child Groups">
                <GroupIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              primary={
                childRelations.length ? (
                  <EntityRefLinks
                    entityRefs={childRelations}
                    defaultKind="Group"
                  />
                ) : (
                  'N/A'
                )
              }
              secondary="Owner of"
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Tooltip title="Parent Group">
                <AccountTreeIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              primary={
                parentRelations.length ? (
                  <EntityRefLinks
                    entityRefs={parentRelations}
                    defaultKind="Group"
                  />
                ) : (
                  'N/A'
                )
              }
              secondary="A part of"
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Tooltip title="Team lead(s)">
                <PersonIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              primary={teamLeads && createTeamLeadLinks(teamLeads)}
              secondary="Team lead(s)"
            />
          </ListItem>
        </Grid>
      </Grid>
      <Grid container direction="column" className={styles.teamInformation}>
        <Typography display="block" variant="h6">
          Description:
        </Typography>
        <Typography variant="body2">
          {description ?? (
            <Alert severity="warning">
              No description available, ask the team lead to edit the group team
              description in Github.
            </Alert>
          )}
        </Typography>
      </Grid>
    </InfoCard>
  );
};
