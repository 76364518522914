import { MissingAnnotationEmptyState } from '@backstage/plugin-catalog-react';
import { EntitySwitch } from '@backstage/plugin-catalog';
import {
  DoraMetricsCard,
  isDoraProfileAvailable,
} from '@internal/plugin-sanofi-dora-metrics';
import { Grid } from '@material-ui/core';
import React from 'react';
import {
  EntityJiraOverviewCard,
  isJiraAvailable,
} from '@roadiehq/backstage-plugin-jira';

export const doraMetricsContent = (
  <Grid container spacing={3}>
    <EntitySwitch>
      <EntitySwitch.Case if={isJiraAvailable || isDoraProfileAvailable}>
        <EntitySwitch>
          <EntitySwitch.Case if={isDoraProfileAvailable}>
            <Grid item md={4} xs={12}>
              <DoraMetricsCard />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>
        <EntitySwitch>
          <EntitySwitch.Case if={isJiraAvailable}>
            <Grid item md={8} xs={12}>
              <EntityJiraOverviewCard />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>
      </EntitySwitch.Case>
      <EntitySwitch.Case>
        <MissingAnnotationEmptyState
          readMoreUrl="https://backstage.prod.accelerator.sanofi/docs/default/system/platform-backstage/plugins/dora-deployment-metrics/"
          annotation={['jira/project-key']}
        />
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);
