import React from 'react';
import { DEFAULT_NAMESPACE, Entity } from '@backstage/catalog-model';
import {
  GroupIcon,
  SidebarDivider,
  SidebarItem,
  SidebarSubmenu,
  SidebarSubmenuItem,
} from '@backstage/core-components';
import TopicIcon from '@material-ui/icons/LibraryAddCheck';
import {
  IconComponent,
  identityApiRef,
  useApi,
  useRouteRef,
} from '@backstage/core-plugin-api';
import useAsync from 'react-use/esm/useAsync';
import {
  catalogApiRef,
  CatalogApi,
  entityRouteRef,
} from '@backstage/plugin-catalog-react';
import { Theme, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles<Theme>(
  theme => ({
    sidebarSubTitle: {
      color: theme.palette.navigation.color,
      fontWeight: 'bold',
      padding: theme.spacing(2, 2),
    },
    sidebarDivider: {
      backgroundColor: theme.palette.navigation.selectedColor,
    },
  }),
  { name: 'BackstageUserEntitiesSideBarNavigation' },
);

const subMenuLabelMap = {
  projects: { title: 'My Projects', icon: GroupIcon },
  groups: { title: 'My Teams', icon: TopicIcon },
};

type Entities = {
  projects: Entity[];
  groups: Entity[];
};

/**
 * UserEntitiesSideBarNavigation can be added to your sidebar providing quick access to groups the logged in user is a member of
 *
 * @public
 */
export const UserEntitiesSideBarNavigation = (props: {
  title: string;
  icon: IconComponent;
}) => {
  const { title, icon } = props;

  const identityApi = useApi(identityApiRef);
  const catalogApi: CatalogApi = useApi(catalogApiRef);
  const catalogEntityRoute = useRouteRef(entityRouteRef);
  const styles = useStyles();
  const { value: entities, error } = useAsync(async () => {
    const profile = await identityApi.getBackstageIdentity();

    const groupEntities = await catalogApi.getEntities({
      filter: [
        {
          kind: 'group',
          'relations.hasMember': profile.userEntityRef,
        },
      ],
      fields: ['metadata', 'kind'],
    });

    const systemEntities = await catalogApi.getEntities({
      filter: groupEntities.items.map(team => ({
        kind: 'system',
        'relations.ownedBy': `group:default/${team.metadata.name}`,
      })),
      fields: ['metadata', 'kind'],
    });

    return { projects: systemEntities, groups: groupEntities };
  }, []);

  if (error || !entities) {
    return <SidebarItem icon={icon} text={title} to="/" />;
  }

  // Member of more than one group
  return (
    <SidebarItem icon={icon} text={title} to="/">
      <SidebarSubmenu title={title}>
        {(Object.keys(entities) as (keyof Entities)[]).map(type => (
          <React.Fragment key={type}>
            <SidebarDivider className={styles.sidebarDivider} />
            <Typography variant="subtitle1" className={styles.sidebarSubTitle}>
              {subMenuLabelMap[type].title}
            </Typography>
            {entities[type].items.map((entity: Entity) => (
              <SidebarSubmenuItem
                key={entity.metadata.uid}
                title={entity.metadata.title || entity.metadata.name}
                to={catalogEntityRoute({
                  kind: entity.kind,
                  namespace: entity.metadata.namespace ?? DEFAULT_NAMESPACE,
                  name: entity.metadata.name,
                })}
              />
            ))}
          </React.Fragment>
        ))}
      </SidebarSubmenu>
    </SidebarItem>
  );
};
