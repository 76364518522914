import React, { ChangeEvent, ChangeEventHandler, useState } from 'react';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import RestorePage from '@material-ui/icons/RestorePage';
import DoneIcon from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';
import { cleanQueryString } from '../../utils';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from '@material-ui/core';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FilteredDoraProject, JiraDoraApi } from '../../api';

export type KeyValueType = {
  key: string;
  value: string;
  originalValue?: string;
  options?: {
    disableKey?: boolean;
    disableValue?: boolean;
    allowEmptyValue?: boolean;
  };
};

type ActionType = 'add' | 'remove' | 'resetToOriginal' | 'resetToEmpty';

type HandleEntryChange = {
  (
    filterType: keyof FilterListType,
    fieldValue: keyof KeyValueType,
    index: number,
  ): (event: ChangeEvent<HTMLInputElement>) => void;
};

export type JiraWorkflowTypes = 'simplified' | 'complex' | 'custom-jql';

const getBaseQueries = ({
  projectKey,
  components,
  jiraStartedFilters,
}: {
  projectKey: string;
  components: string[];
  jiraStartedFilters: KeyValueType[];
}) => {
  return {
    avgChangeLeadTime: cleanQueryString(
      JiraDoraApi.getComputedResolvedQuery(
        'simplified',
        projectKey,
        components,
        jiraStartedFilters,
      ),
    ),
    avgBugResolutionTime: cleanQueryString(
      JiraDoraApi.getComputedResolvedBugQuery(projectKey, components),
    ),
    highPriorityBugsIntroduced: cleanQueryString(
      JiraDoraApi.getComputedHighPriorityBugsIntroducedQuery(
        projectKey,
        components,
      ),
    ),
  };
};
export type FilterListType = {
  jiraStartedFilters: KeyValueType[];
  jiraCompletedFilters: KeyValueType[];
  pipelineFilters: KeyValueType[];
  branchFilters: KeyValueType[];
  queriesFilters: KeyValueType[];
};

export type StoredFilters = {
  workFlowType: JiraWorkflowTypes;
  entries: FilterListType;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
);

export const DEFAULT_FILTER_ENTRIES: FilterListType = {
  jiraStartedFilters: [
    {
      key: 'status',
      value: 'In Progress',
    },
    {
      key: 'status',
      value: 'Building',
    },
  ],
  jiraCompletedFilters: [
    {
      key: 'status',
      value: 'Done',
    },
  ],
  pipelineFilters: [],
  branchFilters: [
    {
      key: 'branch',
      value: '',
      options: {
        disableKey: true,
      },
    },
  ],
  queriesFilters: [
    {
      key: 'avgChangeLeadTime',
      value: '',
      originalValue: '',
      options: {
        allowEmptyValue: true,
      },
    },
    {
      key: 'avgBugResolutionTime',
      value: '',
      originalValue: '',
      options: {
        allowEmptyValue: true,
      },
    },
    {
      key: 'highPriorityBugsIntroduced',
      value: '',
      originalValue: '',
      options: {
        allowEmptyValue: true,
      },
    },
  ],
};

const FilterAccumulator = ({
  handleEntryChange,
  changePipelineFilter,
  disableRemoveButton,
  index,
  entry,
  filterType,
  disableInput,
  disableKey,
  disableValue,
  hideRemoveButton,
}: {
  handleEntryChange: HandleEntryChange;
  changePipelineFilter: ChangePipelineFilter;
  disableRemoveButton: boolean;
  index: number;
  entry: KeyValueType;
  filterType: keyof FilterListType;
  disableInput?: boolean;
  disableKey?: boolean;
  disableValue?: boolean;
  hideRemoveButton?: boolean;
}) => (
  <Grid container alignItems="center" justifyContent="flex-start">
    <Grid item sm={3}>
      <TextField
        key={`index-${filterType}-key`}
        value={entry.key}
        fullWidth
        label="Key"
        onChange={handleEntryChange(filterType, 'key', index)}
        disabled={
          disableInput || filterType === 'queriesFilters' ? true : disableKey
        }
      />
    </Grid>
    <Grid item md={8} sm={7}>
      <TextField
        key={`index-${filterType}-value`}
        fullWidth
        label="Value"
        value={entry.value}
        onChange={handleEntryChange(filterType, 'value', index)}
        disabled={disableInput ? true : disableValue}
      />
    </Grid>

    {/* Handle the restore and remove buttons */}
    {!hideRemoveButton && (
      <Grid item container md={1} direction="row" wrap="nowrap">
        {filterType === 'queriesFilters' ? (
          <>
            <Tooltip title="Use default" arrow>
              <IconButton
                disabled={disableRemoveButton}
                onClick={changePipelineFilter(
                  'resetToOriginal',
                  filterType,
                  index,
                )}
              >
                <RestorePage
                  color={disableRemoveButton ? 'disabled' : 'error'}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Clear" arrow>
              <IconButton
                disabled={disableRemoveButton}
                onClick={changePipelineFilter(
                  'resetToEmpty',
                  filterType,
                  index,
                )}
              >
                <RemoveIcon
                  color={disableRemoveButton ? 'disabled' : 'error'}
                />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <Tooltip title="Delete filter" arrow>
            <IconButton
              disabled={disableRemoveButton}
              onClick={changePipelineFilter('remove', filterType, index)}
            >
              <RemoveIcon color={disableRemoveButton ? 'disabled' : 'error'} />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    )}
  </Grid>
);

const NewFilterButton = ({
  changePipelineFilter,
  label,
  disabled = false,
}: {
  changePipelineFilter: (e: React.MouseEvent<HTMLElement>) => void;
  label: string;
  disabled?: boolean;
}) => {
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Box sx={{ m: 4 }}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            onClick={changePipelineFilter}
            disabled={disabled}
          >
            {label}
          </Button>
        </Grid>
      </Box>
    </Grid>
  );
};

type ChangePipelineFilter = (
  action: ActionType,
  filterType: keyof FilterListType,
  index?: number,
) => () => void;

const FilterList = ({
  entries,
  handleEntryChange,
  changePipelineFilter,
  filterLabel,
  filterButtonLabel,
  filterListType,
  hideRemoveButton,
  disableInput = false,
}: {
  entries: KeyValueType[];
  handleEntryChange: HandleEntryChange;
  changePipelineFilter: ChangePipelineFilter;
  filterLabel: string;
  filterButtonLabel?: string;
  filterListType: keyof FilterListType;
  hideRemoveButton?: boolean;
  disableInput?: boolean;
}) => {
  return (
    <Box sx={{ m: 1 }} justifyContent="center">
      <Typography variant="subtitle1">
        <strong>{filterLabel}</strong>
      </Typography>
      {entries.map((entry, index) => (
        <FilterAccumulator
          entry={entry}
          index={index}
          key={`${filterListType}-${index}-accumulator`}
          handleEntryChange={handleEntryChange}
          disableInput={disableInput}
          disableKey={entry.options?.disableKey}
          disableValue={entry.options?.disableValue}
          changePipelineFilter={changePipelineFilter}
          disableRemoveButton={disableInput}
          hideRemoveButton={hideRemoveButton}
          filterType={filterListType}
        />
      ))}
      {filterButtonLabel && (
        <NewFilterButton
          label={filterButtonLabel}
          disabled={disableInput}
          changePipelineFilter={changePipelineFilter('add', filterListType)}
        />
      )}
    </Box>
  );
};

const DialogForm = ({
  handleEntryChange,
  changePipelineFilter,
  handleRadioChange,
  workflowType,
  entries,
}: {
  handleEntryChange: HandleEntryChange;
  changePipelineFilter: ChangePipelineFilter;
  handleRadioChange: ChangeEventHandler<HTMLInputElement>;
  workflowType: JiraWorkflowTypes;
  entries: FilterListType;
}) => {
  return (
    <FormControl fullWidth>
      <FilterList
        filterLabel="If you want to add specific branch in your deployment pipelines, you can add them here"
        entries={entries.branchFilters}
        handleEntryChange={handleEntryChange}
        changePipelineFilter={changePipelineFilter}
        filterListType="branchFilters"
        hideRemoveButton
      />

      <FilterList
        filterButtonLabel="Add deployment tag filter"
        filterLabel="If you want to look for specific tags in your deployment pipelines, you can add them here"
        entries={entries.pipelineFilters}
        handleEntryChange={handleEntryChange}
        changePipelineFilter={changePipelineFilter}
        filterListType="pipelineFilters"
      />

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <FormLabel component="legend">
          <Typography variant="subtitle1">
            <strong>Choose your Jira workflow</strong>
          </Typography>
        </FormLabel>
        <RadioGroup row value={workflowType} onChange={handleRadioChange}>
          {[
            {
              label: 'We use a simplified workflow',
              value: 'simplified',
            },
            {
              label: 'We use a complex workflow',
              value: 'complex',
            },
            {
              label: 'Custom JQL Query',
              value: 'custom-jql',
            },
          ].map(({ label, value }) => (
            <FormControlLabel
              key={value}
              control={<Radio />}
              label={label}
              value={value}
            />
          ))}
        </RadioGroup>
      </Box>
      {workflowType === 'custom-jql' && (
        <FilterList
          filterLabel="You can use this to help define your own JQL query"
          entries={entries.queriesFilters}
          handleEntryChange={handleEntryChange}
          changePipelineFilter={changePipelineFilter}
          filterListType="queriesFilters"
          disableInput={workflowType !== 'custom-jql'}
        />
      )}
      {workflowType !== 'custom-jql' && (
        <FilterList
          filterButtonLabel="Add new completed filters"
          filterLabel="Add specific key values to search for any tickets in a state which your team considers to be completed"
          entries={entries.jiraCompletedFilters}
          handleEntryChange={handleEntryChange}
          changePipelineFilter={changePipelineFilter}
          filterListType="jiraCompletedFilters"
          disableInput={workflowType === 'simplified'}
        />
      )}
      <FilterList
        filterButtonLabel="Add new 'In progress' filters"
        filterLabel="Add specific key values where your team may consider a ticket to be in progress. This could be a status or a label or any JQL key value pair"
        entries={entries.jiraStartedFilters}
        handleEntryChange={handleEntryChange}
        changePipelineFilter={changePipelineFilter}
        filterListType="jiraStartedFilters"
        disableInput={workflowType === 'simplified'}
      />
    </FormControl>
  );
};

export const ConfigurationDialog = ({
  settings,
  projectKey,
  components,
  handleChange,
}: {
  settings: StoredFilters;
  projectKey: string;
  components: FilteredDoraProject[];
  handleChange: (settings: StoredFilters) => void;
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [workflowType, setWorkflowType] =
    useState<JiraWorkflowTypes>('simplified');
  const [entries, setEntries] = useState({} as FilterListType);

  const openDialog = () => {
    setEntries(settings.entries);
    setWorkflowType(settings.workFlowType ?? 'simplified');
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };
  // Handle the radio change and resetting values where necessary
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const workFlowType = event.target.value as JiraWorkflowTypes;
    const filteredComponents = components
      .filter(x => x.jiraComponent)
      .map(x => x.jiraComponent);

    const baseQueries = getBaseQueries({
      projectKey,
      components: filteredComponents,
      jiraStartedFilters: entries.jiraStartedFilters,
    });

    setEntries({
      ...DEFAULT_FILTER_ENTRIES,
      queriesFilters: DEFAULT_FILTER_ENTRIES.queriesFilters.map(
        (query, index) => {
          const existing = entries.queriesFilters.find(
            x => x.key === query.key,
          );
          return {
            ...DEFAULT_FILTER_ENTRIES.queriesFilters[index],
            value:
              existing?.value ||
              baseQueries[query.key as keyof typeof baseQueries],
          };
        },
      ),
    });
    setWorkflowType(workFlowType);
  };

  const changePipelineFilter =
    (
      action: ActionType,
      filterType: keyof FilterListType,
      index?: number | undefined,
    ) =>
    () => {
      if (action === 'add') {
        // adds a new key value pair to the end of the array
        setEntries({
          ...entries,
          [filterType]: [
            ...entries[filterType as keyof FilterListType],
            { key: '', value: '' },
          ],
        });
      } else if (action === 'remove') {
        // removes a key value pair from the array
        setEntries({
          ...entries,
          [filterType]: entries[filterType as keyof FilterListType].filter(
            (_, entryIndex) => entryIndex !== index,
          ),
        });
      } else if (action === 'resetToOriginal') {
        // restore the default value from the array
        setEntries({
          ...entries,
          [filterType]: entries[filterType].map((filter, entryIndex) => {
            if (entryIndex !== index) {
              return filter;
            }
            return { ...filter, value: filter.originalValue };
          }),
        });
      } else if (action === 'resetToEmpty') {
        // restore the default value from the array
        setEntries({
          ...entries,
          [filterType]: entries[filterType].map((filter, entryIndex) => {
            if (entryIndex !== index) {
              return filter;
            }
            return { ...filter, value: '' };
          }),
        });
      }
    };

  const handleEntryChange =
    (
      filterType: keyof FilterListType,
      fieldValue: keyof KeyValueType,
      index: number,
    ) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const updatableEntries =
        entries[filterType as keyof FilterListType][index];
      updatableEntries[fieldValue] = event.currentTarget.value;
      setEntries({
        ...entries,
        [filterType]: entries[filterType as keyof FilterListType].map(
          (entry, entryIndex) =>
            entryIndex === index ? updatableEntries : entry,
        ),
      });
    };

  const handleSubmit = () => {
    const updatePayload: StoredFilters = {
      workFlowType: workflowType as JiraWorkflowTypes,
      entries: entries,
    };

    handleChange(updatePayload);
    closeDialog();
  };

  return (
    <>
      <IconButton onClick={openDialog}>
        <SettingsIcon />
      </IconButton>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={closeDialog}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          <Box display="flex" justifyContent="center">
            Configure my DORA metrics
          </Box>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogForm
            handleEntryChange={handleEntryChange}
            changePipelineFilter={changePipelineFilter}
            handleRadioChange={handleRadioChange}
            workflowType={workflowType}
            entries={entries}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            startIcon={<DoneIcon />}
            color="primary"
          >
            Apply
          </Button>
          <Button
            onClick={closeDialog}
            startIcon={<CloseIcon />}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
