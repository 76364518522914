import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const overviewPlugin = createPlugin({
  id: 'overview',
  routes: {
    root: rootRouteRef,
  },
  featureFlags: [{ name: 'finsecops-v2' }],
});

export const OverviewPage = overviewPlugin.provide(
  createRoutableExtension({
    name: 'OverviewPage',
    component: () =>
      import('./components/OverviewPage').then(m => m.OverviewBasePage),
    mountPoint: rootRouteRef,
  }),
);
