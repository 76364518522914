import { FileObject, ResourceObject, RowFormedObject } from './types';

const nameByResourceType: { [key: string]: string } = {
  apiGateway: 'Name',
  cloudFormation: 'StackName',
  cloudfront: 'DomainName',
  dynamoDB: 'TableName',
  ec2: 'InstanceId',
  lambda: 'FunctionName',
  rds: 'DBInstanceIdentifier',
  s3Bucket: 'Name',
};

export const orderListPriority = (
  list: string[],
  ...args: string[]
): string[] => {
  const newList = [];
  args.forEach(arg => {
    const item = list.find(i => i === arg);
    if (item) {
      newList.push(item);
    }
  });
  newList.push(...list);
  return Array.from(new Set(newList));
};

export function decorEnvWithIcon(environment: string) {
  switch (environment) {
    case 'dev':
      return `🚧 ${environment}`;
    case 'test':
      return `🧪 ${environment}`;
    case 'prod':
      return `🏭 ${environment}`;
    default:
      return environment;
  }
}

const fillResourceObject = (
  resourceName: string,
  resourceList: ResourceObject[],
  isError: boolean = false,
) => {
  return resourceList.map((res: ResourceObject) => ({
    ...res,
    id: `${res.value[nameByResourceType[resourceName]]}|${res.env}`,
    env: res.env,
    type: resourceName,
    status: res.error ? 'ERROR' : 'PASSED',
    name: res.value[nameByResourceType[resourceName]],
    region: res.region,
    value: res.value,
    error: isError ? res.error : undefined,
  })) as unknown as RowFormedObject[];
};

export const formDataFetched = (rawData: FileObject) => {
  let taggedResources: RowFormedObject[] = [];
  let untaggedResources: RowFormedObject[] = [];

  if (rawData.untagged) {
    Object.entries(rawData.untagged).forEach(([resource, item]) => {
      const result = fillResourceObject(resource, item, true);
      untaggedResources = [...untaggedResources, ...result];
    });
  }

  Object.entries(rawData.tagged).forEach(([_teamName, resourceName]) => {
    Object.entries(resourceName).forEach(([resourceKey, { passed, error }]) => {
      const resultPassed = fillResourceObject(resourceKey, passed);
      const resultError = fillResourceObject(resourceKey, error, true);
      taggedResources = [...taggedResources, ...resultPassed, ...resultError];
    });
  });
  return { tagged: taggedResources, untagged: untaggedResources };
};
