import {
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  createRouteRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const entityContentRouteRef = createRouteRef({
  id: 'security-insights',
});

export const securityInsightsPlugin = createPlugin({
  id: 'security-insights',
  routes: {
    entityContent: entityContentRouteRef,
  },
});

export const EntityGithubDependabotContent = securityInsightsPlugin.provide(
  createComponentExtension({
    name: 'EntityGithubDependabotContent',
    component: {
      lazy: () =>
        import('./components/GithubDependabotTab').then(
          m => m.GithubDependabotTab,
        ),
    },
  }),
);

export const EntityDependabotAlertsCard = securityInsightsPlugin.provide(
  createComponentExtension({
    name: 'EntityDependabotAlertsCard',
    component: {
      lazy: () =>
        import('./components/DependabotAlertsWidget').then(
          m => m.DependabotAlertsWidget,
        ),
    },
  }),
);

export const dependabotMonitoringPlugin = createPlugin({
  id: 'sanofi-dependabot-monitoring',
  routes: {
    root: rootRouteRef,
  },
});

export const DependabotMonitoringPage = dependabotMonitoringPlugin.provide(
  createRoutableExtension({
    name: 'SanofiDependabotMonitoringPage',
    component: () =>
      import('./components/EntryComponent').then(m => m.EntryComponent),
    mountPoint: rootRouteRef,
  }),
);
