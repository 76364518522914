import React, { useCallback, useMemo, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { Table } from '@backstage/core-components';
import ListIcon from '@material-ui/icons/ListAlt';
import { Box, Button, TextField } from '@material-ui/core';

export default {
  title: 'Layout/Dialog',
  component: Dialog,
};

export interface Annotation {
  key: string;
  value: string;
  id: string;
  configurable: boolean;
}
const generateTableColumns = (
  handleChange: (key: string, value: string) => void,
) => [
  {
    title: 'Annotation',
    field: 'key',
  },
  {
    title: 'Value',
    field: 'value',
    render: (rowData: Annotation) => {
      return (
        <TextField
          value={rowData.value}
          onChange={e => handleChange(rowData.key, e.target.value)}
          key={rowData.key}
          fullWidth
          variant="outlined"
          size="small"
          disabled={!rowData.configurable}
        />
      );
    },
  },
];

export const ConfigureEntityDialog = ({
  configurableAnnotations = [],
  entityAnnotations = {},
  title,
  handleSubmit,
}: {
  configurableAnnotations: string[];
  entityAnnotations?: Record<string, string>;
  title: string;
  kind: string;
  handleSubmit: (annotations: Annotation[]) => Promise<void>;
}) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState<Annotation[]>([]);
  const handleCallSubmit = (annotations: Annotation[]) =>
    handleSubmit(annotations)
      .then(() => setOpen(false))
      .catch(() => {});

  // Generate the table columns
  const columns = useMemo(() => {
    const handleChange = (key: string, val: string): void => {
      const updated = values.map(v =>
        v.key === key ? { ...v, value: val } : v,
      );
      setValues(updated);
    };
    return generateTableColumns(handleChange);
  }, [values]);

  const handleSetValues = useCallback (() => {
    const tableData = Object.entries(entityAnnotations).map(([key, value]) => {
      return {
        key,
        value,
        id: key,
        configurable: configurableAnnotations.includes(key),
      };
    });

    // Add in the configurable annotations that don't exist in the entity annotations
    configurableAnnotations.forEach(key => {
      if (!tableData.find(v => v.key === key)) {
        tableData.push({ key, value: '', id: key, configurable: true });
      }
    });

    // Sort the annotations so that the non-configurable ones are at the bottom
    tableData.sort(a => {
      if (!a.configurable) {
        return 1;
      }
      return -1;
    });

    setValues(tableData);
  }, [entityAnnotations, configurableAnnotations]);

  // reset the values back to the original annotations and close modal
  const handleReset = () => {
    setOpen(false);
    handleSetValues();
  };

  // Initialize the table data with the existing annotations or check if the values have changed
if (configurableAnnotations.length && !values.length) {
    handleSetValues();
  }

  return (
    <>
      <IconButton
        aria-label="Annotate"
        title="Add annotations"
        onClick={() => setOpen(true)}
      >
        <ListIcon />
      </IconButton>
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        onClose={handleReset}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <Box display="flex" justifyContent="flex-end">
          <IconButton aria-label="close" onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Table
            title={title}
            subtitle="Add annotations to the entity. Annotations are key-value pairs that can be used to store additional information about your team and enable plugins for Backstage."
            options={{ paging: false, search: false }}
            columns={columns}
            data={values}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleCallSubmit(values)}
          >
            Update entity
          </Button>
          <Button onClick={handleReset}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
