import type { History } from './jira';
import { JiraDoraApi, jiraDoraApiRef, JiraIssue } from './jira';
import {
  DatadogDoraApi,
  datadogDoraApiRef,
  DatadogPipelineResult,
} from './datadog';
import {
  ConfigApi,
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { CompoundEntityRef } from '@backstage/catalog-model';
import { groupFilterByName } from '../utils';
import {
  KeyValueType,
  StoredFilters,
} from '../components/Modals/ConfigurationDialog';
import { Metric } from '../components/DoraMetricsCard/DoraMetrics';

export type Options = {
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
  fetchApi: FetchApi;
};

export type FilteredDoraProject = {
  jiraComponent: string;
  repositorySlug: string;
  deploymentPipeline: string;
  deploymentJobName: string;
  hasMultipleOwners: boolean;
};

export type DoraFetchRequest = {
  period: string;
  jiraProjectKey: string;
  filteredProjects: FilteredDoraProject[];
  compoundEntityRef: CompoundEntityRef;
  entityDoraSettings: StoredFilters;
};

export type MetricType = {
  value: string;
  metrics: Metric[];
};

export type DoraMetricsPayload = {
  avgChangeLeadTime: MetricType;
  avgBugResolutionTime: MetricType;
  changeFailPercentage: MetricType;
  deploymentFrequency: MetricType;
  highPriorityBugsIntroduced: MetricType;
};

type ComputedJiraDoraIssue = JiraIssue & { _computed: Record<string, any> };

type ComputedJiraIssues = ComputedJiraDoraIssue[];

export const doraApiRef = createApiRef<DoraMetrics>({
  id: 'plugin.sanofi-dora-metrics.service',
});

const JIRA_STATUS = {
  BUILDING: 'Building',
  IN_PROGRESS: 'In Progress',
};

export const WORKING_MONTH_DAYS = 22;

class DoraMetrics {
  private readonly jira: JiraDoraApi;
  private readonly datadog: DatadogDoraApi;

  constructor(options: Options) {
    this.jira = new JiraDoraApi(options);
    this.datadog = new DatadogDoraApi(options);
  }

  convertMsToDays(ms: number): string {
    return Math.abs(ms / 1000 / 60 / 60 / 24).toFixed(2);
  }

  calculateResolutionTime(startDate: Date, endDate: Date) {
    const deltaTime = endDate.getTime() - startDate.getTime();
    return Math.abs(deltaTime / (1000 * 3600 * 24)).toFixed(1);
  }

  /**
   *
   * This function checks the changelog for the first change to in progress or first status change.
   * It will search each issues changelog set and return the first change to in progress or first status change.
   * If neither are found, it will return the created date.
   * At the end, it will return the average of all of the lead times.
   */
  calculateAverageChangeLeadTime(
    issues: Array<JiraIssue>,
    {
      jiraStartedFilters,
      workFlowType,
    }: {
      jiraCompletedFilters: KeyValueType[];
      jiraStartedFilters: KeyValueType[];
      workFlowType: string;
    },
    logOutput: { logValue: boolean; logId: string | null } = {
      logValue: false,
      logId: null,
    },
  ): {
    valueAvgChangeLeadTime: string;
    computedIssuesAvgChangeLeadTime: ComputedJiraIssues;
  } {
    const total = {
      firstChange: 0,
      firstStatus: 0,
      created: 0,
    };
    const { logValue, logId } = logOutput;
    const computedIssues: ComputedJiraIssues = [];
    const leadTimes = issues.map((issue: JiraIssue) => {
      const { resolutiondate, created } = issue.fields;
      if (!resolutiondate) {
        return 0;
      }
      const resolutionDate = new Date(resolutiondate);
      const createdDate = new Date(created);
      const { histories } = issue.changelog;

      const fallBack = [JIRA_STATUS.IN_PROGRESS, JIRA_STATUS.BUILDING];
      const [jiraStartValues] = [jiraStartedFilters].map(groupFilterByName);

      const testProgressHistories = histories.filter(history => {
        const exists = history.items.find(item => {
          const isStatus = item.field === 'status';
          const hasStatusChange =
            workFlowType === 'simplified'
              ? fallBack.includes(item.toString)
              : [...(jiraStartValues?.status || []), ...fallBack].includes(
                  item.toString,
                );

          return isStatus && hasStatusChange;
        });

        return !!exists;
      });

      const firstChangeToInProgress = this.getFirstChange(
        testProgressHistories,
      );

      const statusChanges = histories.filter(history => {
        const exists = history.items.find(item => item.field === 'status');
        return !!exists;
      });

      const firstStatusChange = this.getFirstChange(statusChanges);

      const startDate = this.createChangeDate(
        firstChangeToInProgress,
        firstStatusChange,
        createdDate,
      );

      this.setStatusChange(firstChangeToInProgress, firstStatusChange, total);
      const resolutionTime = resolutionDate.getTime() - startDate.getTime();
      const computedIssue: ComputedJiraDoraIssue =
        issue as ComputedJiraDoraIssue;
      computedIssue._computed = {
        startDate,
        resolutionTime: this.convertMsToDays(resolutionTime),
      };
      computedIssues.push(computedIssue);
      return resolutionDate.getTime() - startDate.getTime();
    });

    const output = {
      firstProgressChange: (total.firstChange / issues.length) * 100,
      firstStatusChange: (total.firstStatus / issues.length) * 100,
      created: (total.created / issues.length) * 100,
      total: total.firstChange + total.firstStatus + total.created,
    };

    if (logValue) {
      // eslint-disable-next-line no-console
      console.log(`Generated DORA output for ${logId}`, JSON.stringify(output));
    }

    const avgResolutionTimeInMilliseconds =
      leadTimes.reduce((a, b) => a + b, 0) / leadTimes.length;

    // Handle 0 / 0
    if (isNaN(avgResolutionTimeInMilliseconds)) {
      return {
        valueAvgChangeLeadTime: '0',
        computedIssuesAvgChangeLeadTime: computedIssues,
      };
    }

    return {
      valueAvgChangeLeadTime: this.convertMsToDays(
        avgResolutionTimeInMilliseconds,
      ),
      computedIssuesAvgChangeLeadTime: computedIssues,
    };
  }

  /**
   * This function will increment the total for the first change to in progress, first status change, or created.
   * We use this to calculate the percentage of each teams attributed status changes
   */
  setStatusChange(
    firstChangeToInProgress: History | null,
    firstStatusChange: History | null,
    total: {
      firstStatus: number;
      created: number;
      firstChange: number;
    },
  ) {
    if (firstChangeToInProgress) {
      total.firstChange += 1;
      return;
    }
    if (firstStatusChange) {
      total.firstStatus += 1;
      return;
    }
    total.created += 1;
  }

  /**
   * This function will return the first change event for all of the changelogs provided.
   * If the status is not found, it will return the first status change.
   * If the status is not found, it will return the created date.
   */
  createChangeDate(
    firstChangeToInProgress: History | null,
    firstStatusChange: History | null,
    createdDate: Date,
  ) {
    if (firstChangeToInProgress?.created) {
      return new Date(firstChangeToInProgress.created);
    }

    if (firstStatusChange?.created) {
      return new Date(firstStatusChange.created);
    }
    return createdDate;
  }

  /**
   *
   * This function will calculate the average resolution time for all of the bugs provided.
   * It takes the resolutionDate and the createdDate and calculates the difference.
   * It then returns the average of all of the resolution lead times.
   */
  getBugResolutionTime(issues: Array<JiraIssue>): {
    valueBugResolutionTime: string;
    computedIssuesBugResolutionTime: ComputedJiraDoraIssue[];
  } {
    const computedIssues: ComputedJiraIssues = [];
    const leadTimes = issues.map(issue => {
      const resolutionDate = new Date(issue.fields.resolutiondate);
      const createdDate = new Date(issue.fields.created);
      issue.fields.timeToResolve = this.calculateResolutionTime(
        createdDate,
        resolutionDate,
      );
      const resolutionTime = resolutionDate.getTime() - createdDate.getTime();
      const computedIssue: ComputedJiraDoraIssue =
        issue as ComputedJiraDoraIssue;
      computedIssue._computed = {
        startDate: createdDate,
        resolutionTime: this.convertMsToDays(resolutionTime),
      };
      computedIssues.push(computedIssue);

      return resolutionDate.getTime() - createdDate.getTime();
    });

    const avgResolutionTimeInMilliseconds =
      leadTimes.reduce((a, b) => a + b, 0) / leadTimes.length;

    // Handle 0 / 0
    if (isNaN(avgResolutionTimeInMilliseconds)) {
      return {
        valueBugResolutionTime: '0',
        computedIssuesBugResolutionTime: computedIssues,
      };
    }

    return {
      valueBugResolutionTime: this.convertMsToDays(
        avgResolutionTimeInMilliseconds,
      ),
      computedIssuesBugResolutionTime: computedIssues,
    };
  }

  getComputedHighPriorityBugs(issues: JiraIssue[]): ComputedJiraIssues {
    return issues.map(issue => {
      const { resolutiondate, created } = issue.fields;
      if (!resolutiondate) {
        return issue as ComputedJiraDoraIssue;
      }
      const resolutionDate = new Date(resolutiondate);
      const createdDate = new Date(created);
      const resolutionTime = resolutionDate.getTime() - createdDate.getTime();
      const computedIssue: ComputedJiraDoraIssue =
        issue as ComputedJiraDoraIssue;
      computedIssue._computed = {
        startDate: createdDate,
        resolutionTime: this.convertMsToDays(resolutionTime),
      };

      return computedIssue;
    });
  }

  /**
   * This function will return the latest value from the changelog based on when the change occured.
   * If the created date is before the current value, it will return the current value.
   */
  getFirstChange(value: History[]): History | null {
    return value.reduce((curr: History | null, history) => {
      if (!curr) {
        return history;
      }

      if (new Date(history.created) < new Date(curr.created)) {
        return history;
      }

      return curr;
    }, null);
  }

  /**
   *
   * This function will calculate the change fail percentage.
   * It takes the number of high priority bugs and the number of deployments and calculates the percentage.
   */
  calculateChangeFailPercentage(
    highPriorityBugs: JiraIssue[],
    deployments: DatadogPipelineResult[],
  ): string {
    if (deployments.length === 0 || highPriorityBugs.length === 0) {
      return '0';
    }
    return ((highPriorityBugs.length / deployments.length) * 100).toFixed(2);
  }

  async getDoraMetricsForProject({
    jiraProjectKey,
    period = '30d',
    filteredProjects,
    compoundEntityRef,
    entityDoraSettings,
  }: DoraFetchRequest): Promise<DoraMetricsPayload> {
    const { workFlowType } = entityDoraSettings;
    const {
      jiraStartedFilters,
      jiraCompletedFilters,
      pipelineFilters,
      branchFilters,
      queriesFilters,
    } = entityDoraSettings.entries;

    const { name: teamName } = compoundEntityRef;
    const [
      { resolvedBugs, resolvedFeatures, highPriorityBugsIntroduced },
      datadogDeployments,
    ] = await Promise.all([
      this.jira.getJiraIssuesForProject(
        jiraProjectKey,
        filteredProjects.map(x => x.jiraComponent),
        jiraCompletedFilters,
        workFlowType,
        queriesFilters,
      ),
      this.datadog.getTeamDeployments(
        period,
        pipelineFilters,
        teamName,
        branchFilters,
        filteredProjects,
      ),
    ]);

    const { valueAvgChangeLeadTime, computedIssuesAvgChangeLeadTime } =
      this.calculateAverageChangeLeadTime(
        resolvedFeatures.results,
        { jiraStartedFilters, jiraCompletedFilters, workFlowType },
        {
          logValue: true,
          logId: jiraProjectKey,
        },
      );

    const { valueBugResolutionTime, computedIssuesBugResolutionTime } =
      this.getBugResolutionTime(resolvedBugs.results);

    return {
      avgBugResolutionTime: {
        value: valueBugResolutionTime,
        metrics: [
          {
            metricUnit: 'production bugs repaired',
            metricValue: resolvedBugs.results.length,
            query: resolvedBugs.jql,
            data: computedIssuesBugResolutionTime,
            source: 'jira',
          },
        ],
      },
      avgChangeLeadTime: {
        value: valueAvgChangeLeadTime,
        metrics: [
          {
            metricUnit: 'tickets completed',
            metricValue: resolvedFeatures.results.length,
            query: resolvedFeatures.jql,
            data: computedIssuesAvgChangeLeadTime,
            source: 'jira',
          },
        ],
      },
      changeFailPercentage: {
        value: this.calculateChangeFailPercentage(
          this.getComputedHighPriorityBugs(highPriorityBugsIntroduced.results),
          datadogDeployments,
        ),
        metrics: [
          {
            metricUnit: 'deployments',
            metricValue: datadogDeployments.length,
            data: datadogDeployments,
            source: 'datadog',
          },
          {
            metricUnit: 'production bugs introduced',
            metricValue: highPriorityBugsIntroduced.results.length,
            query: highPriorityBugsIntroduced.jql,
            data: highPriorityBugsIntroduced.results,
            source: 'jira',
          },
        ],
      },
      deploymentFrequency: {
        value: (datadogDeployments.length / WORKING_MONTH_DAYS).toFixed(2),
        metrics: [
          {
            metricUnit: 'deployments',
            metricValue: datadogDeployments.length,
            data: datadogDeployments,
            source: 'datadog',
          },
          {
            metricUnit: 'working days',
            metricValue: WORKING_MONTH_DAYS,
            data: [],
          },
        ],
      },
      highPriorityBugsIntroduced: {
        value: `${highPriorityBugsIntroduced.results.length}`,
        metrics: [
          {
            metricUnit: 'deployments',
            metricValue: datadogDeployments.length,
            data: datadogDeployments,
            source: 'datadog',
          },
          {
            metricUnit: 'production bugs',
            metricValue: highPriorityBugsIntroduced.results.length,
            query: highPriorityBugsIntroduced.jql,
            data: highPriorityBugsIntroduced.results,
            source: 'jira',
          },
        ],
      },
    };
  }
}
export {
  jiraDoraApiRef,
  JiraDoraApi,
  datadogDoraApiRef,
  DatadogDoraApi,
  DoraMetrics,
};
