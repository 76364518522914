import React, { PropsWithChildren } from 'react';
import { makeStyles, SvgIconTypeMap, Tooltip } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import ToolIcon from '@material-ui/icons/Build';
import CloudIcon from '@material-ui/icons/CloudQueue';
import CodeIcon from '@material-ui/icons/Code';
import Dashboard from '@material-ui/icons/Dashboard';
import BallotIcon from '@material-ui/icons/Ballot';
import FormatPaint from '@material-ui/icons/FormatPaint';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearch } from '@backstage/plugin-search';
import {
  Link,
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { UserEntitiesSideBarNavigation } from '../navigation/UserEntitiesSideBarNavigation';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarSearch />
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <UserEntitiesSideBarNavigation title="Home" icon={HomeIcon} />
        <SidebarItem icon={ToolIcon} to="toolbox" text="Developer tools" />
        <SidebarItem
          icon={FormatPaint}
          to="https://elements.sanofidesign.com/557b0250b/p/71eae0-elements-design-system"
          target="_blank"
          text="Design System"
        />
        <SidebarDivider />
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem
          icon={OfflineBoltOutlinedIcon}
          to="scaffolder"
          text="Workflows"
        />
        <SidebarItem icon={Dashboard} to="overview" text="FinSecOps" />
        <SidebarItem
          icon={BallotIcon}
          to="catalog?filters[user]=all"
          text="Catalog"
        />
        {/* End global nav */}
        <SidebarDivider />
        <SidebarScrollWrapper>
          <SidebarItem icon={MapIcon} to="radar/data" text="Data Tech Radar" />
          {[
            {
              path: 'radar/engineering',
              title: 'Engineering Tech Radar',
              icon: CodeIcon,
            },
            {
              path: 'radar/cloud-saas',
              title: 'Cloud Saas Radar',
              icon: CloudIcon,
            },
          ].map(
            ({
              path,
              title,
              icon,
            }: {
              path: string;
              title: string;
              icon: OverridableComponent<SvgIconTypeMap>;
            }) => (
              <Tooltip key={path} title={title} arrow>
                <SidebarItem icon={icon} to={path} text={title} />
              </Tooltip>
            ),
          )}
        </SidebarScrollWrapper>
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
