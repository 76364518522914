import { graphql } from '@octokit/graphql';
import { DenseTableProps, Node } from '../types';
import { addRepoNameInEachIssue } from '../components/utils/DependabotRepositoryTools';

const urlGithubBasePath = 'https://api.github.com';

export const widgetQuery = `
  query GetDependabotAlertsWidget($name: String!, $owner: String!) {
    repository(name: $name, owner: $owner) {
      vulnerabilityAlerts(first: 100, states: OPEN) {
        totalCount
        nodes {
          state 
          createdAt
          id
          dismissedAt
          vulnerableManifestPath
          securityVulnerability {
            vulnerableVersionRange
            package {
              name
            }
            firstPatchedVersion {
              identifier
            }
            severity
            advisory {
              description
            }
          }
        }
      }
    }
  }`;

export const dependabotAlertsQuery = `
  query GetDependabotAlerts($name: String!, $owner: String!) {
    repository(name: $name, owner: $owner) {
      vulnerabilityAlerts(first: 100) {
        totalCount
        nodes {
          state
          createdAt
          id
          vulnerableManifestPath
          securityVulnerability {
            vulnerableVersionRange
            package {
              name
            }
            firstPatchedVersion {
              identifier
            }
            severity
            advisory {
              description
            }
          }
        }
      }
    }
  }`;

const githubApiHeaders = (token: string) => ({
  Accept: 'application/vnd.github+json',
  Authorization: `Bearer ${token}`,
  'X-GitHub-Api-Version': '2022-11-28',
});
export const aggregateRepositoryDependabotInfo = async (
  repoList: string[],
  org: string,
  hostname: string,
  query: string,
  gqlEndpoint: any,
) => {
  const nodes: Node[] = [];
  const handleRepoName: string[] = [];
  const handleDependabotRepoResponse: Promise<DenseTableProps>[] = [];
  repoList.forEach(repo => {
    handleDependabotRepoResponse.push(
      gqlEndpoint(query, { name: repo, owner: org }),
    );
    handleRepoName.push(repo);
  });
  const repositoryReadErrors: { message: string }[] = [];
  const resolvedPromises = await Promise.allSettled(
    handleDependabotRepoResponse,
  );
  resolvedPromises.forEach((req, index) => {
    if (req.status === 'rejected') {
      repositoryReadErrors.push(req.reason);
      return;
    }
    const { vulnerabilityAlerts } = addRepoNameInEachIssue(
      req.value?.repository,
      handleRepoName[index],
      org,
      hostname,
    );
    nodes.push(...vulnerabilityAlerts.nodes);
  });
  return {
    err: repositoryReadErrors,
    data: {
      vulnerabilityAlerts: {
        totalCount: nodes.length,
        nodes,
      },
    },
  };
};

export const getPaginatedData = async (
  url: URL | string,
  headers: {
    Accept: string;
    Authorization: string;
    'X-GitHub-Api-Version': string;
  },
) => {
  const nextPattern = /<([^>]+)>;\s*rel="next"/i;
  let data: { name: string }[] = [];
  let nextPageUrl: string | URL | null = url;
  while (nextPageUrl) {
    const response: Response = await fetch(nextPageUrl, {
      method: 'GET',
      headers,
    });
    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }
    const responseData = await response.json();
    data = [...data, ...responseData];
    const linkHeader: string | null = response.headers.get('Link');
    const nextLinkMatch = linkHeader && nextPattern.exec(linkHeader);
    nextPageUrl = nextLinkMatch ? nextLinkMatch[1] : null;
  }
  return data;
};

export const getRepoListForOrg = async (org: string, token: string) => {
  try {
    const url = `${urlGithubBasePath}/orgs/${org}/repos?per_page=50`;
    const headers = githubApiHeaders(token);
    const rawResponse = await getPaginatedData(url, headers);
    return rawResponse.map(repo => repo.name);
  } catch (err: any) {
    throw new Error(`Error fetching data: ${err.message}`);
  }
};

export const getRepoListForTeam = async (
  org: string,
  teamName: string,
  token: string,
) => {
  try {
    const url = `${urlGithubBasePath}/orgs/${org}/teams/${teamName}/repos?per_page=50`;
    const headers = githubApiHeaders(token);
    const rawResponse = await getPaginatedData(url, headers);
    return rawResponse.map(repo => repo.name);
  } catch (err: any) {
    throw new Error(`Error fetching data: ${err.message}`);
  }
};

export const queryForDependabot = ({
  token,
  repoList,
  org,
  widgetView = false,
}: {
  token: string;
  repoList: string[];
  org: string;
  widgetView?: boolean;
}) => {
  const { hostname, baseUrl } = {
    hostname: 'github.com',
    baseUrl: 'https://api.github.com',
  };
  const gqlEndpoint = graphql.defaults({
    baseUrl,
    headers: {
      authorization: `token ${token}`,
    },
  });
  return aggregateRepositoryDependabotInfo(
    repoList,
    org,
    hostname,
    widgetView ? widgetQuery : dependabotAlertsQuery,
    gqlEndpoint,
  );
};
