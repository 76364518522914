import { doraApiRef, DoraMetrics } from './api';
import {
  configApiRef,
  createPlugin,
  createApiFactory,
  discoveryApiRef,
  createComponentExtension,
  fetchApiRef,
} from '@backstage/core-plugin-api';

export const sanofiDoraMetricsPlugin = createPlugin({
  id: 'sanofi-dora-metrics',
  apis: [
    createApiFactory({
      api: doraApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        configApi: configApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, configApi, fetchApi }) => {
        return new DoraMetrics({
          discoveryApi,
          configApi,
          fetchApi,
        });
      },
    }),
  ],
});

export const DoraMetricsCard = sanofiDoraMetricsPlugin.provide(
  createComponentExtension({
    name: 'DoraMetricsCard',
    component: {
      lazy: () => import('./components/Cards').then(m => m.DoraMetricsCard),
    },
  }),
);
