import { BarChartData, ChartSeries } from './types';

export const DAY_IN_SECONDS = 24 * 60 * 60;

const escapeSpaces = (query: string) => {
  return query.replace(/\s+/g, ' ').trim();
};

const escapeCarrierReturn = (query: string) => {
  return query.replace(/(\r\n|\n|\r)/gm, '');
};

export const cleanQueryString = (query: string) => {
  const queryWithoutSpaces = escapeSpaces(query);
  return escapeCarrierReturn(queryWithoutSpaces);
};

export const calculateTimestamps = (period: string) => {
  const currentTime = new Date();
  const acceptedPeriods = ['60d', '90d'];
  if (!acceptedPeriods.includes(period)) {
    throw new Error(
      `Invalid period value. Accepted values: ${acceptedPeriods.join(', ')}`,
    );
  }
  const days = parseInt(period, 10);
  const fromTimestamp = currentTime.getTime() - days * (24 * 60 * 60 * 1000);
  const nowTimestamp = currentTime.getTime();
  return { fromTimestamp, nowTimestamp };
};

export const groupSumBy30Days = (data: number[]) => {
  const totalSums: number[] = [];
  let remainingDays = data.length;
  while (remainingDays > 0) {
    const daysToSum = Math.min(30, remainingDays);
    const startIndex = data.length - remainingDays;
    const endIndex = startIndex + daysToSum;
    const sum = data
      .slice(startIndex, endIndex)
      .reduce((acc, curr) => acc + curr, 0);
    totalSums.push(sum);
    remainingDays -= daysToSum;
  }
  return totalSums;
};

export const generateChartSeries = (
  seriesData: { group_tags: string[] }[],
  times: string[],
  values: number[][],
): ChartSeries[] => {
  const xData = times.map((time: string) => {
    return new Date(time).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
    });
  });
  return seriesData.map((s: { group_tags: string[] }, index: number) => {
    const label = s.group_tags[0].replace('aws_product:', '');
    const stack = 'A';
    const data = values[index].map((value: number) => value ?? 0);
    const totalSums = groupSumBy30Days(data);
    const total = totalSums.reduce((acc, curr) => acc + curr, 0);
    return { data, stack, label, total, totalSums, xData };
  });
};

export const sumTotalSumsByIndex = (
  chartDataSeries: ChartSeries[],
): number[] => {
  const totalSumsLength = chartDataSeries[0]?.totalSums.length || 0;
  const sumByIndex = Array(totalSumsLength).fill(0);
  chartDataSeries.forEach(series => {
    series.totalSums.forEach((sum, index) => {
      sumByIndex[index] += sum;
    });
  });
  return sumByIndex;
};

export const barChartSeries = (inputDataList: BarChartData[]) => {
  return inputDataList.map((inputData: BarChartData) => {
    const { data, stack, label } = inputData;
    return {
      data,
      stack,
      label,
    };
  });
};

export const colorPalette = [
  '#1f77b4',
  '#ff7f0e',
  '#2ca02c',
  '#d62728',
  '#9467bd',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf',
  '#7fc97f',
  '#beaed4',
  '#fdc086',
  '#ffff99',
  '#386cb0',
  '#f0027f',
  '#bf5b17',
  '#666666',
];
