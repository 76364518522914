import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import React from 'react';

export const diagramResourceSystem = (
  <EntityCatalogGraphCard
    variant="gridItem"
    direction={Direction.TOP_BOTTOM}
    title="System Diagram"
    height={700}
    relations={[
      RELATION_PART_OF,
      RELATION_HAS_PART,
      RELATION_API_CONSUMED_BY,
      RELATION_API_PROVIDED_BY,
      RELATION_CONSUMES_API,
      RELATION_PROVIDES_API,
      RELATION_DEPENDENCY_OF,
      RELATION_DEPENDS_ON,
    ]}
    unidirectional={false}
  />
);
